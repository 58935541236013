import { Image, Tabs, Tooltip } from 'antd';
import type { TabsProps } from 'antd';


import yifangUrl from'../assets/images/1-about/yifang-20240105.jpg';
// import yifangUrl from'../assets/images/1-about/yifang-summer-2.jpg';
// import yifangUrl from'../assets/images/1-about/yifang.jpeg';
// import yifangUrl from'../assets/images/1-about/yifang-fall.JPG';
// import yifangUrl from'../assets/images/1-about/yifang-winter.jpg';

import DietGlanceUrl from '../assets/images/2-research/DietGlance.jpg';
import InclusiVizUrl from '../assets/images/2-research/inclusionviz.jpg';
import lossGenerationUrl from '../assets/images/2-research/loss-generation.jpg';
import CatVisUrl from '../assets/images/2-research/isea2024.jpg';
import NeighVizUrl from '../assets/images/2-research/vds2023-neighviz-img.jpeg';
import LonelyIslandUrl from '../assets/images/2-research/visap2023-lonelyisland.jpg';
import LonelyIslandStoryUrl from '../assets/images/3-data-art/visap2023-lonelyisland/Chinavisap2024-0.JPG';
import InnovationInsightsUrl from '../assets/images/2-research/vis2023-innovationinsights-img-3.JPG';
// import InnovationInsightsUrl from '../assets/images/2-research/vis2023-innovationinsights-img-2.jpg';
// import InnovationInsightsUrl from '../assets/images/2-research/vis2023-innovationinsights-img.jpeg';
import ComparativeStudyUrl from '../assets/images/2-research/tvcg2024-comparative.jpg';
import HealthPrismUrl from '../assets/images/2-research/vis2023-healthprism-img.jpg';
import TCKUrl from '../assets/images/2-research/jcst2023-survey-tcp.jpeg';
import ACSeekerUrl from '../assets/images/2-research/vis2021-acseeker-teaser.jpeg';
import CareerLensUrl from '../assets/images/2-research/tvcg-2020-careerlens-img.jpeg';
import visap2021Url from '../assets/images/2-research/visap2021-shijing-img.jpeg';
import mTSeerUrl from '../assets/images/2-research/chi2021-mtseer-img.jpeg';
import CloudDetUrl from '../assets/images/2-research/vis2019-cloudet-img.jpeg';
import pulseUrl from '../assets/images/2-research/2018-pulse-img.jpeg';
import MARVisTUrl from '../assets/images/2-research/tvcg2018-marvist-img.jpeg';
import urbanARUrl from '../assets/images/2-research/vi2017-urbanar-img.jpeg';

import DietGlancePaperUrl from '../assets/paper-material/2025-DietGlance.pdf';
import InclusionVizPaperUrl from '../assets/paper-material/pvis2025-InclusionViz/PVIS2024_InclusiViz.pdf';
import CatVisPaperUrl from '../assets/paper-material/isea2024/ISEA2024_Taurine_0405_Final.pdf';
import NeighVizPaperUrl from '../assets/paper-material/vds2023-neighviz/vds2023-neighviz-paper.pdf';
import LonelyIslandPaperUrl from '../assets/paper-material/visap2023-lonelyisland/vis23k-sub1032-i6.pdf';
import InnovationInsightsPaperUrl from '../assets/paper-material/4-innovationinsights/vis2023_innovationsinsights_paper_20230808.pdf';
import ComparativeStudyPaperUrl from '../assets/paper-material/tvcg2024-comparative.pdf';
import HealthPrismPaperUrl from '../assets/paper-material/vis2023-healthprism/vis2023-healthprism-paper.pdf';
import TCKPaperUrl from '../assets/paper-material/jcst2023-survey-tcp.pdf';
import ACSeekerPaperUrl from '../assets/paper-material/1-acseeker/vis2021-acseeker-paper.pdf';
import CareerLensPaperUrl from '../assets/paper-material/2-careerlens/tvcg-2020-careerlens-paper.pdf';
import visap2021PaperUrl from '../assets/paper-material/3-the-book-of-songs/visap2021-shijing-paper.pdf';
import mTSeerPaperUrl from '../assets/paper-material/chi2021-mtseer-paper.pdf';
import CloudDetPaperUrl from '../assets/paper-material/vis2019-cloudet-paper.pdf';
import pulsePaperUrl from '../assets/paper-material/2018-pulse-paper.pdf';
import MARVisTPaperUrl from '../assets/paper-material/tvcg2018-marvist-paper.pdf';
import urbanARPaperUrl from '../assets/paper-material/vi2017-urbanar-paper.pdf';

import ChinaInMapsVideoUrl from '../assets/images/3-data-art/chinainmaps/China_in_Maps_video.mp4' 
import ChinaInMapsImgUrl from '../assets/images/3-data-art/chinainmaps/China_in_Maps_teaser.jpg'
import lossGenerationVideoUrl from '../assets/paper-material/siggraphart2024-lossgeneration/artg_205s1-yaml3_video.mp4';
import LonelyIslandVideoUrl from '../assets/paper-material/visap2023-lonelyisland/video.mp4';
import InnovationInsightsBackgroundBackgroundVideoUrl from '../assets/paper-material/4-innovationinsights/vis2023_innovationsinsights_video_background.mp4';
import InnovationInsightsBackgroundCaseVideoUrl from '../assets/paper-material/4-innovationinsights/vis2023_innovationsinsights_video_case.mp4';
import HealthPrismVideoUrl from '../assets/paper-material/vis2023-healthprism/vis2023-healthprism-demo-video.mp4';
import ACSeekerVideoUrl from '../assets/paper-material/1-acseeker/vis2021-acseeker-demo-video.mp4';
import CareerLensVideoUrl from '../assets/paper-material/2-careerlens/tvcg-2020-careerlens-demo-video.mp4';
import visap2021VideoUrl from '../assets/paper-material/3-the-book-of-songs/visap.mp4';

import generationLossSlidesUrl from '../assets/paper-material/siggraph2024-loss-of-sonnet-18.pdf';
import InnovationInsightsSlidesUrl from '../assets/paper-material/4-innovationinsights/vis2023-presentation.pdf';
import ACSeekerSlidesUrl from '../assets/paper-material/1-acseeker/vis2021-acseeker-slides.pdf';
import CareerLensSlidesUrl from '../assets/paper-material/2-careerlens/vis2021-careerlens-slides.pdf';
import visap2021SlidesUrl from '../assets/paper-material/3-the-book-of-songs/visap2021-presentation.pdf';

import InclusionVizPosterUrl from '../assets/paper-material/pvis2025-InclusionViz/ChinaVis2024_PosterPaper_InclusiViz.pdf';
import NeighVizSuppUrl from '../assets/paper-material/vds2023-neighviz/vds2023-neighviz-supp.pdf';
import InnovationInsightsSuppUrl from '../assets/paper-material/4-innovationinsights/vis2023_innovationsinsights_supp_dataset_20230808.pdf';
import InnovationInsightsPosterUrl from '../assets/paper-material/4-innovationinsights/icssi2023_innnovationinsights_poster.pdf';
import ACSeekerSuppUrl from '../assets/paper-material/1-acseeker/vis2021-acseeker-supp.pdf';
import CareerLensSuppUrl from '../assets/paper-material/2-careerlens/tvcg-2020-careerlens-supp.pdf';
import visap2021SuppUrl from '../assets/paper-material/3-the-book-of-songs/supp.pdf';

import shijingUrl from '../assets/images/3-data-art/visap2021-shijing-img.jpeg';
import dunhuangUrl from '../assets/images/3-data-art/visap2022-dunhuang.jpeg';
import vis22ReflectionUrl from '../assets/images/3-data-art/vis2022-reflection.jpeg';

import cvUrl from '../assets/images/1-about/ap__cv__2024Fall_yifang_wang.pdf'
import youtubeIconUrlsm from '../assets/images/4-contacts/202407/youtube.png';
import photoIconUrlsm from '../assets/images/4-contacts/202407/500px.png';
import scholarIconUrlsm from '../assets/images/4-contacts/202407/google-scholar.png';
import cvIconUrlsm from '../assets/images/4-contacts/202407/cv.png';
import twitterIconUrlsm from '../assets/images/4-contacts/202407/twitter.png';
import emailIconUrlsm from '../assets/images/4-contacts/202407/email.png';
import scholarIconUrl from '../assets/images/4-contacts/nu/google-scholar.png';
import githubIconUrl from '../assets/images/4-contacts/nu/github.png';
import twitterIconUrl from '../assets/images/4-contacts/nu/twitter.png';
import emailIconUrl from '../assets/images/4-contacts/nu/email.png';
import photoIconUrl from '../assets/images/4-contacts/nu/500px.png';

import delphiUrl from '../assets/images/6-rd/delphi.jpeg';
import urbanUrl from '../assets/images/6-rd/smart_campus.jpeg';
import pulse2Url from '../assets/images/6-rd/pulse.jpeg';

import nuUrl from '../assets/images/7-institution-logo/nu.png';
import hkustUrl from '../assets/images/7-institution-logo/hkust.svg';
import zhejianglabUrl from '../assets/images/7-institution-logo/zhejiang_lab.png';
import zjuUrl from '../assets/images/7-institution-logo/zju.png';

// news
import computationalArtExhibitionUrl from '../assets/images/8-news/computational-art-exhibition.png';
import ISEA2024Logo  from '../assets/images/8-news/ISEA2024.jpg';
import InfoPlus23 from '../assets/images/8-news/info+2023.jpg';
import VIS23InnovationInsightsB from '../assets/images/8-news/InnovationInsights-2.jpg';
import VIS23InnovationInsightsA from '../assets/images/8-news/InnovationInsights-1.jpg';
import VISAP23B from '../assets/images/8-news/VISAP23-19.jpeg';
import VISAP23A from '../assets/images/8-news/VISAP23-4.jpeg';
import chinainmaps from '../assets/images/8-news/chinainmaps-1.jpeg';
import IIBLogo from '../assets/images/8-news/IIB-logo.png';
// import vis2023Logo from '../assets/images/8-news/vis2023-logo.svg';
import sparksImg from '../assets/images/8-news/sparks.jpg';
import cssiLogo from '../assets/images/8-news/cssi-logo.jpg';


// data art story
import CatVisVideoUrl from '../assets/images/3-data-art/isea2024/cat-video.mp4';
import LonelyIslandStoryImgUrl1 from '../assets/images/3-data-art/visap2023-lonelyisland/story/lonely-1.jpg';
import LonelyIslandStoryImgUrl2 from '../assets/images/3-data-art/visap2023-lonelyisland/story/lonely-2.jpg';
import TabPane from 'antd/es/tabs/TabPane';
import { Link } from 'react-router-dom';

function importAll(r: any) {
  return r.keys().map(r);
}
const ChinaInMapsImgUrlList: string[] = importAll(require.context('../assets/images/3-data-art/chinainmaps/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
const LonelyIslandStoryImgUrlList: string[] = importAll(require.context('../assets/images/3-data-art/visap2023-lonelyisland/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
const CatVisImgUrlList: string[] = importAll(require.context('../assets/images/3-data-art/isea2024/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
const DunhuangImgUrlList: string[] = importAll(require.context('../assets/images/3-data-art/chinavisap2022/', false, /\.(jpg|png|jpe?g|svg|JPG|PNG)$/))
const LossGenerationImgUrlList: string[] = importAll(require.context('../assets/images/3-data-art/siggraphart2024/', false, /\.(png|jpe?g|svg|JPG|png|PNG|mp4|MP4)$/)) 
const Visap2021ImgUrlList: string[] = importAll(require.context('../assets/images/3-data-art/visap2021/', false, /\.(png|jpe?g|svg|JPG|png|PNG|webp|mp4|MP4)$/))

// data art award logo
const LossGenerationAwardLogoList: string[] = importAll(require.context('../assets/images/3-data-art/prize-logo/LossGeneration/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
const CatVisAwardLogoList: string[] = importAll(require.context('../assets/images/3-data-art/prize-logo/CatVis/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
const Visap2021AwardLogoList: string[] = importAll(require.context('../assets/images/3-data-art/prize-logo/Visap2021/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
const LonelyIslandAwardLogoList: string[] = importAll(require.context('../assets/images/3-data-art/prize-logo/LonelyIsland/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))

export enum dataArtAwardCategory {
  VISAP2021 = 'VISAP 2022',
  VISAP2023 = 'VISAP 2023',

  ElectricDreams2022 = 'Electric Dreams 2022',

  IIB2022 = 'Information is Beautiful 2022',
  IIB2023 = 'Information is Beautiful 2023',

  ISEA2024 = 'ISEA 2024', 
  SIGGRAPHART = 'SIGGRAPH ART Gallery 2024', 

  CHINAVISAP2024 = 'CHINAVISAP 2024',

  INFOPLUS = 'Information+ Conference 2023', 

  LumenPrize2024 = 'The Lumen Prize 2024', // The Lumen Prize
}

export enum paperCategory {
  FEATURED = 'Featured',
  ALL = 'All',
  CSS = 'Computational Social Science',
  DH = 'Digital Humanities',
  DR = 'Data-driven Art',
  VA = 'Visual Analytics',
  IS = 'Immersive Visual Analytics',
}

export enum newsCategory {
  IN2025 = '2025',
  IN2024 = '2024',
  IN2023 = '2023',
  BEFORE2023 = 'Before 2023',
  ALL = 'All',
  TALK = 'Talk',
  MEDIA = 'Media',
  AWARDS = 'Awards',
  VISITS = 'Visits',
  CONFERENCE = 'Conference',
}

export enum experienceCategory {
  EXPERIENCE = 'Experience',
  SERVICE = 'Service',
  RD = 'R&D',
  TEACHING = 'Teaching',
}

const SciSciIntro: string = '💡 The science of science (SciSci) is based on a transdisciplinary approach that uses large data sets to study the mechanisms underlying the doing of science—from the choice of a research problem to career trajectories and progress within a field. (By Fortunato, Santo, et al. "Science of science." Science 359.6379 (2018): eaao0185)'
const socialMobilityIntro: string = "💡 Social mobility is the movement of individuals, families, households or other categories of people within or between social strata in a society. It is a change in social status relative to one's current social location within a given society. This movement occurs between layers or tiers in an open system of social stratification. (By Wikipedia)"
const dataArtIntro: string = "💡 Information art, which is also known as informatism or data art, is an emerging art form that is inspired by and principally incorporates data, computer science, information technology, artificial intelligence, and related data-driven fields. (By Wikipedia)"


export const textContent = {
  aboutPage: {
    title: 'Hello, 你好！',
    introduction: <div>
      <p>Yifang Wang (王懿芳) is currently a Post-Doctoral Fellow in the <a href="https://www.kellogg.northwestern.edu/research/science-of-science.aspx" target="_blank" rel="external">Center for Science of Science &amp; Innovation (CSSI)</a> at Kellogg School of Management, <a href="https://www.northwestern.edu/" target="_blank" rel="external"> Northwestern University</a>, with <a href="https://www.dashunwang.com/" target="_blank" rel="external">Prof. Dashun WANG</a>. Before that, she obtained a Ph.D. degree in the Department of Computer Science and Engineering from The Hong Kong University of Science and Technology (HKUST, 2022), at <a href="http://vis.cse.ust.hk/index.html" target="_blank" rel="external">HKUST VisLab</a>, under the supervision of <a href="http://huamin.org/" target="_blank" rel="external">Prof. Huamin QU</a>. She obtained her B.Eng. degree (major: Software Engineering) from <a href="http://www.zju.edu.cn/" target="_blank" rel="external">Zhejiang University</a> (ZJU, 2018). During her undergraduate studies, she worked in <a href="http://zjuidg.org/" target="_blank" rel="external">ZJUIDG Group</a> at the <a href="http://www.cad.zju.edu.cn/" target="_blank" rel="external">State Key Lab of CAD &amp; CG</a>, supervised by <a href="http://www.ycwu.org/" target="_blank" rel="external">Prof. Yingcai WU</a>.</p>

      <p>Her research lies at the intersection of Data Visualization (VIS), Human-Computer Interaction (HCI), Artificial Intelligence (AI), and Computational Social Science (CSS), aiming to 
        <span className='css-framework-step' style={{ color: '#E7C15F'}}> (1) understand</span>, 
        <span className='css-framework-step' style={{ color: '#2A9D8F'}}> (2) communicate</span>, and 
        <span className='css-framework-step' style={{ color: '#264653'}}> (3) inform decisions </span> on essential questions about human behavior and societal dynamics, especially those related to <span className='css-framework-step' style={{ color: '#4F2582'}}>societal decision-making</span>.

        {/* add a list of keywords */}
        {/* <li>Data Visualization</li> */}
      </p>
      <p>
      The interdisciplinary nature of her research and her experience in <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted', textUnderlineOffset: '4px' }}>computer science</span> and <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted', textUnderlineOffset: '4px' }}>business school</span> has also led her to actively engage across multiple research communities, working with over 50 researchers from fields such as network science, sociology, communication, and media art, and others, covering areas like the
        {/* The interdisciplinary nature of her research has led her to become an active participant in computer science, computational social science, and art communities, collaborating with more than fifty researchers from different fields, such as network science, sociology, communication, humanities, media art, and others, covering areas like the  */}
        <Tooltip placement="right" title={SciSciIntro} className='tooltip'>
          <a href="https://www.science.org/doi/10.1126/science.aao0185" target="_blank" rel="external" className='highlight'> science of science and innovation (SciSci)</a>
        </Tooltip>
        , 
        <Tooltip placement="right" title={socialMobilityIntro} className='tooltip'>
          <a href="https://www.nature.com/articles/s41586-022-04996-4" target="_blank" rel="external" className='highlight'> social mobility and inequity</a>
        </Tooltip>
        , 
        public health, and more. 
        She also works closely with policy stakeholders on real-world societal decision-making problems, including funding agencies (e.g., <a href="https://www.afrl.af.mil/AFOSR/" target="_blank" rel="external">AFOSR</a> and <a href="https://www.nsf.gov/" target="_blank" rel="external">NSF</a>), venture capital firms (e.g., <a href="https://deerfield.com/" target="_blank" rel="external">Deerfield</a>), and leaders from top-tier universities (e.g., Northwestern and Vanderbilt). 
        These partnerships ensure her research is not only academically rigorous but also impactful and attuned to real-world decision-making needs. 

        The outcomes of her research include not only use-inspired visual analytics systems for decision-makers and social scientists, but also data-driven artworks that intuitively convey insights to the general public. 
      </p>
      {/* <p>Her research interests lie broadly in visual analytics of computational social science (e.g., 
        <Tooltip placement="right" title={SciSciIntro} className='tooltip'>
          <a href="https://www.science.org/doi/10.1126/science.aao0185" target="_blank" rel="external" className='highlight'> Science of Science & Innovation</a>
        </Tooltip>
        , 
        <Tooltip placement="right" title={socialMobilityIntro} className='tooltip'>
          <a href="https://www.nature.com/articles/s41586-022-04996-4" target="_blank" rel="external" className='highlight'> Social Mobility & Inequity</a>
        </Tooltip>
        , and Public Health) and digital humanities. 
        
        She also explores 
        <Tooltip placement="right" title={dataArtIntro} className='tooltip'>
          <a href="https://en.wikipedia.org/wiki/Information_art" target="_blank" rel="external" className='highlight'> data-driven art</a>
        </Tooltip>
        , data storytelling, and immersive visual analytics with AR & VR. </p> */}
      {/* <p> Her current research focus is visual analytics for the
        <Tooltip placement="right" title={SciSciIntro} className='tooltip'>
          <a href="https://www.science.org/doi/10.1126/science.aao0185" target="_blank" rel="external" className='highlight'> Science of Science & Innovation</a>
        </Tooltip>
        , aiming to apply visualization, AI, and data analysis techniques to facilitate scientific discoveries and decision-making for the scientific community and the broader society.</p> */}
      {/* <p>Her research interests lie broadly in visual analytics of computational social science and digital humanities. She is also interested in data-driven art, data storytelling, immersive visual analytics with AR & VR, and visual anomaly detection. Her current research focuses include visual analytics of
        <Tooltip placement="right" title={SciSciIntro} className='tooltip'>
          <a href="https://www.science.org/doi/10.1126/science.aao0185" target="_blank" rel="external" className='highlight'> Science of Science</a>
        </Tooltip>
        ,
        <Tooltip placement="right" title={socialMobilityIntro} className='tooltip'>
          <a href="https://www.nature.com/articles/s41586-022-04996-4" target="_blank" rel="external" className='highlight'> Social Mobility and Inequity</a>
        </Tooltip>
        , and
        <Tooltip placement="right" title={dataArtIntro} className='tooltip'>
          <a href="https://en.wikipedia.org/wiki/Information_art" target="_blank" rel="external" className='highlight'> dara-driven art </a>
        </Tooltip>
        about social science and humanities.</p> */}
      {/* <p>She is a computer scientist, computational social scientist, and data artist. </p> */}
      <p>She enjoys photography, music, and observing life. 
         {/* add an icon in antd to link to the lifePage --  a router */}
         <Link to="/lifePage">
            &nbsp;<span className='highlight-life'>Yifang's Glimpse of Life </span>
            <img src={youtubeIconUrlsm} style={{height: '1.3em'}}/>
         </Link>
         {/* <a href='/lifePage'><img src={youtubeIconUrlsm} style={{height: '1.5em'}}/></a>.  */}
          {/* She is also a <a href='https://500px.com.cn/yifangwang'>photographer <img src={photoIconUrlsm} style={{height: '1.5em'}}/></a>, <a href='https://www.youtube.com/watch?v=hkT2pYdimPQ&list=PLPvA04rAJ83mw84oCIcVBrzZFBf912PZv'>music enthusiast <img src={youtubeIconUrlsm} style={{height: '1.5em'}}/></a>, and <a href='https://www.youtube.com/watch?v=JOLXq997Hr4&list=PLPvA04rAJ83lr3p24mOcaGtbLe0-qVJmb'>life watcher <img src={youtubeIconUrlsm} style={{height: '1.5em'}}/></a>.  */}
        {/* She is always seeking and exploring a balance of sense and sensibility, in both research and life.  */}
      </p>
      <div className='highlight-sentence' style={{marginTop: '3rem'}}>
        <p>She is now on the job market, looking for a tenure-track position in computer science, information school, data science, and any places that welcome interdisciplinary researchers. </p>
        <p>Feel free to <a href='mailto:yifang.wang@kellogg.northwestern.edu'>contact her</a> if you have any opportunities! </p>
        {/* She is quite open-minded and always welcomes collaborations in data visualization, social science/humanities, and art. Feel free to <a href='#contactPage'>contact her</a> if you are interested in collaboration!!  */}
      </div>
    </div>,
    profileImageUrl: yifangUrl,
  },
  researchPage: [
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.FEATURED, paperCategory.VA],
      teaser: DietGlanceUrl,
      title: 'DietGlance: Dietary Monitoring and Personalized Analysis at a Glance with Knowledge-Empowered AI Assistant',
      authorList: ['Zhihan Jiang', 'Running Zhao', 'Lin Lin', 'Yue Yu', 'Handi Chen', 'Xinchen Zhang', 'Xuhai Xu', 'Yifang Wang', 'Xiaojuan Ma', 'Edith C.H. Ngai'], 
      venue: 'arXiv',
      venueShort: 'arXiv',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: 'https://kellogg-cssi.github.io/InnovationInsights/'},
        {name: 'PDF', fileLink: 'https://arxiv.org/abs/2502.01317'},
        // {name: 'Supp Material', fileLink: InclusiVizSuppUrl},
        // {name: 'Slides (VIS 2023)', fileLink: InnovationInsightsSlidesUrl},
        // {name: 'Talk (VIS 2023)', fileLink: 'https://www.youtube.com/watch?v=YxSExcb6vAQ'},
        // {name: 'Poster (ChinaVis 2024)', fileLink: InclusionVizPosterUrl},
        // {name: 'Oral Talk (IC2S2 2024)', fileLink: '#'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.FEATURED, paperCategory.VA],
      teaser: InclusiVizUrl,
      title: 'InclusiViz: Visual Analytics of Human Mobility Data for Understanding and Mitigating Urban Segregation',
      authorList: ['Yue Yu', 'Yifang Wang', 'Yongjun Zhang', 'Huamin Qu', 'Dongyu Liu'], 
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'PacificVis2025 (TVCG Journal Track)',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: 'https://kellogg-cssi.github.io/InnovationInsights/'},
        {name: 'PDF', fileLink: InclusionVizPaperUrl},
        // {name: 'Supp Material', fileLink: InclusiVizSuppUrl},
        // {name: 'Slides (VIS 2023)', fileLink: InnovationInsightsSlidesUrl},
        // {name: 'Talk (VIS 2023)', fileLink: 'https://www.youtube.com/watch?v=YxSExcb6vAQ'},
        {name: 'Poster (ChinaVis 2024)', fileLink: InclusionVizPosterUrl},
        // {name: 'Oral Talk (IC2S2 2024)', fileLink: '#'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.FEATURED, paperCategory.VA],
      teaser: InnovationInsightsUrl,
      title: 'InnovationInsights: A Visual Analytics Approach for Understanding the Dual Frontiers of Science and Technology',
      authorList: ['Yifang Wang', 'Yifan Qian', 'Xiaoyu Qi', 'Nan Cao', 'Dashun Wang'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'VIS2023',
      award: 'Best Paper Honorable Mention',
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://kellogg-cssi.github.io/InnovationInsights/'},
        {name: 'PDF', fileLink: InnovationInsightsPaperUrl},
        {name: 'Background Video', fileLink: InnovationInsightsBackgroundBackgroundVideoUrl},
        {name: 'Case Video', fileLink: InnovationInsightsBackgroundCaseVideoUrl},
        {name: 'Supp Material', fileLink: InnovationInsightsSuppUrl},
        {name: 'Slides (VIS 2023)', fileLink: InnovationInsightsSlidesUrl},
        {name: 'Talk (VIS 2023)', fileLink: 'https://www.youtube.com/watch?v=YxSExcb6vAQ'},
        {name: 'Poster (ICSSI 2023)', fileLink: InnovationInsightsPosterUrl},
        // {name: 'Oral Talk (IC2S2 2024)', fileLink: '#'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.DR],
      teaser: lossGenerationUrl,
      title: 'Loss of Sonnet 18',
      authorList: ['Junxiu Tang', 'Jiayi Zhou', 'Yifang Wang', 'Xinhuan Shu', 'Peiquan Xia', 'Xiaojiao Chen', 'Tan Tang', 'Yingcai Wu'],
      venue: 'SIGGRAPH 2024, Art Gallery',
      venueShort: 'SIGGRAPH Art 2024',
      award: '',
      suppMaterialList: [
        {name: 'PDF', fileLink: 'https://dl.acm.org/doi/10.1145/3641523.3669939'},
        {name: 'Official Website', fileLink: 'https://s2024.conference-program.org/presentation/?id=artg_205&sess=sess284'},
        {name: 'Demo Video', fileLink: lossGenerationVideoUrl},
        {name: 'Slides', fileLink: generationLossSlidesUrl}, 
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.DR],
      teaser: CatVisUrl,
      title: '"Hidden Incidents of Pet Food": Portraying Taurine Deficiency in Cats',
      authorList: ['Ziwei Wu', 'Xiaofu Jin', 'Danlu Fei', 'Yifang Wang', 'Guangda Zhu', 'Kang Zhang'],
      venue: 'The International Symposium on Electronic Art (the top conference in media art)',
      venueShort: 'ISEA2024',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: 'https://osf.io/qdpzm/'},
        {name: 'PDF', fileLink: CatVisPaperUrl},
      ]
    },
    {
      category: [paperCategory.ALL],
      teaser: ComparativeStudyUrl,
      title: 'A Comparative Study on Fixed-order Event Sequence Visualizations: Gantt, Extended Gantt, and Stringline Charts',
      authorList: ['Junxiu Tang', 'Fumeng Yang', 'Jiang Wu', 'Yifang Wang', 'Jiayi Zhou', 'Xiwen Cai', 'Lingyun Yu', 'Yingcai Wu'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'TVCG2024',
      award: '',
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://osf.io/qdpzm/'},
        {name: 'PDF', fileLink: ComparativeStudyPaperUrl},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.FEATURED, paperCategory.VA],
      teaser: HealthPrismUrl,
      title: "HealthPrism: A Visual Analytics System for Exploring Children's Physical and Mental Health Profiles with Multimodal Data",
      authorList: ['Zhihan Jiang', 'Handi Chen', 'Rui Zhou', 'Jing Deng', 'Xinchen Zhang', 'Running Zhao', 'Cong Xie', 'Yifang Wang*', 'Edith C.H. Ngai*'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'VIS2023',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: 'https://kellogg-cssi.github.io/InnovationInsights/'},
        {name: 'PDF', fileLink: HealthPrismPaperUrl},
        {name: 'Demo Video', fileLink: HealthPrismVideoUrl},
        // {name: 'Slides', fileLink: '#'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.DR],
      teaser: LonelyIslandUrl,
      title: 'Posts with no Response: The Island of Loneliness',
      authorList: ['Junxiu Tang', 'Rui Sheng', 'Yifang Wang', 'Xinhuan Shu', 'Xiaojiao Chen', 'Tan Tang', 'Yingcai Wu'],
      venue: 'IEEE VIS Arts Program (accepted in two tracks: Pictorial and Artwork)',
      venueShort: 'VISAP2023',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: ''},
        {name: 'PDF', fileLink: LonelyIslandPaperUrl},
        {name: 'Demo Video', fileLink: LonelyIslandVideoUrl},
        {name: 'VISAP2023 Official Website', fileLink: 'https://visap.net/2023/contributions/posts-with-no-response-the-island-of-loneliness'},
        {name: 'Info+ 2023 Official Website', fileLink: 'https://informationplusconference.com/2023/#program'},
        // {name: 'Slides', fileLink: ACSeekerSlidesUrl},
        // {name: 'Talk', fileLink: 'https://www.youtube.com/watch?v=y4UaKwzBeNY'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.FEATURED, paperCategory.VA],
      teaser: NeighVizUrl,
      title: 'NeighViz: Towards Better Understanding of Neighborhood Effects on Social Groups with Spatial Data',
      authorList: ['Yue Yu', 'Yifang Wang*', 'Qisen Yang', 'Di Weng', 'Yongjun Zhang', 'Xiaogang Wu', 'Yingcai Wu', 'Huamin Qu'],
      venue: 'Visualization in Data Science (VDS at IEEE VIS)',
      venueShort: 'VDS2023',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: 'https://kellogg-cssi.github.io/InnovationInsights/'},
        {name: 'PDF', fileLink: NeighVizPaperUrl},
        // {name: 'Case Video', fileLink: InnovationInsightsBackgroundCaseVideoUrl},
        {name: 'Supp Material', fileLink: NeighVizSuppUrl},
        // {name: 'ICSSI2023 Poster', fileLink: InnovationInsightsPosterUrl},
        // {name: 'Slides', fileLink: ACSeekerSlidesUrl},
        // {name: 'Talk', fileLink: 'https://www.youtube.com/watch?v=y4UaKwzBeNY'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.DH],
      teaser: TCKUrl,
      title: 'Computational Approaches for Traditional Chinese Painting: From the "Six Principles of Painting" Perspective',
      authorList: ['Wei Zhang', 'Jianwei Zhang', 'Jason Wong', 'Yifang Wang', 'Yingchaojie Feng', 'Luwei Wang', 'Wei Chen'],
      venue: 'Journal of Computer Science and Technology',
      venueShort: 'JCST2023',
      award: '',
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://ca4tcp.com/'},
        {name: 'PDF', fileLink: TCKPaperUrl},
        {name: 'Slides', fileLink: '#'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.FEATURED, paperCategory.VA],
      teaser: ACSeekerUrl,
      title: 'Seek for Success: A Visualization Approach for Understanding the Dynamics of Academic Careers',
      authorList: ['Yifang Wang', 'Tai-Quan Peng', 'Huihua Lu', 'Haoren Wang', 'Xiao Xie', 'Huamin Qu', 'Yingcai Wu'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'TVCG2022',
      award: '',
      suppMaterialList: [
        {name: 'Project Page', fileLink: './xxxxxx'},
        {name: 'PDF', fileLink: ACSeekerPaperUrl},
        {name: 'Demo Video', fileLink: ACSeekerVideoUrl},
        {name: 'Supp Material', fileLink: ACSeekerSuppUrl},
        {name: 'Slides', fileLink: ACSeekerSlidesUrl},
        {name: 'Talk', fileLink: 'https://www.youtube.com/watch?v=y4UaKwzBeNY'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.DH, paperCategory.FEATURED, paperCategory.VA],
      teaser: CareerLensUrl,
      title: 'Interactive Visual Exploration of Longitudinal Historical Career Mobility Data',
      authorList: ['Yifang Wang', 'Hongye Liang', 'Xinhuan Shu', 'Jiachen Wang', 'Ke Xu', 'Zikun Deng', 'Cameron Campbell', 'Bijia Chen', 'Yingcai Wu', 'Huamin Qu'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'TVCG2022',
      award: '',
      suppMaterialList: [
        {name: 'Project Page', fileLink: './xxxxxx'},
        {name: 'PDF', fileLink: CareerLensPaperUrl},
        {name: 'Demo Video', fileLink: CareerLensVideoUrl},
        {name: 'Supp Material', fileLink: CareerLensSuppUrl},
        {name: 'Slides', fileLink: CareerLensSlidesUrl},
        {name: 'Talk', fileLink: 'https://www.youtube.com/watch?v=jHhK1ajh96g'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.DH, paperCategory.DR, paperCategory.FEATURED],
      teaser: visap2021Url,
      title: 'Explore Mindfulness without Deflection: A Data Art Based on the Book of Songs',
      authorList: ['Yifang Wang', 'Yifan Cao', 'Junxiu Tang', 'Yang Wang', 'Huamin Qu', 'Yingcai Wu'],
      venue: 'IEEE VIS Arts Program',
      venueShort: 'VISAP2021',
      award: '',
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://wangyifang.github.io/the-book-of-songs/'},
        {name: 'PDF', fileLink: visap2021PaperUrl},
        {name: 'Demo Video', fileLink: visap2021VideoUrl},
        {name: 'Supp Material', fileLink: visap2021SuppUrl},
        {name: 'Slides', fileLink: visap2021SlidesUrl},
        {name: 'Talk', fileLink: 'https://www.youtube.com/watch?v=5fNTfPpeXQs'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.VA],
      teaser: mTSeerUrl,
      title: 'mTSeer: Interactive Visual Exploration of Models on Multivariate Time-series Forecast',
      authorList: ['Ke Xu', 'Jun Yuan', 'Yifang Wang', 'Claudio Silva', 'Enrico Bertini'],
      venue: 'Proceedings of the SIGCHI Conference on Human Factors in Computing Systems',
      venueShort: 'CHI2021',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: './xxxxxx'},
        {name: 'PDF', fileLink: mTSeerPaperUrl},
        {name: 'Demo Video', fileLink: './xxxxxx'},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.VA],
      teaser: CloudDetUrl,
      title: 'CloudDet: Interactive Visual Analysis of Anomalous Performances in Cloud Computing Systems',
      authorList: ['Ke Xu', 'Yun Wang', 'Leni Yang', 'Yifang Wang', 'Bo Qiao', 'Si Qin', 'Yong Xu', 'Haidong Zhang', 'Huamin Qu'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'VIS2020',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: './xxxxxx'},
        {name: 'PDF', fileLink: CloudDetPaperUrl},
        {name: 'Demo Video', fileLink: './xxxxxx'},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.VA],
      teaser: pulseUrl,
      title: 'Pulse: Toward a Smart Campus by Communicating Real-time Wi-Fi Access Data',
      authorList: ['Aoyu Wu', 'Bon Kyung Ku', 'Furui Cheng', 'Xinhuan Shu', 'Abishek Puri', 'Yifang Wang', 'Huamin Qu'],
      venue: 'Workshop on Visualization for Communication, the IEEE Visualization Conference',
      venueShort: 'VIS2018',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: './xxxxxx'},
        {name: 'PDF', fileLink: pulsePaperUrl},
        // {name: 'Demo Video', fileLink: './xxxxxx'},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.IS],
      teaser: MARVisTUrl,
      title: 'MARVisT: Authoring Glyph-based Visualization in Mobile Augmented Reality',
      authorList: ['Zhutian Chen', 'Yijia Su', 'Yifang Wang', 'Qianwen Wang', 'Huamin Qu', 'Yingcai Wu'],
      venue: 'IEEE Transactions on Visualization and Computer Graphics',
      venueShort: 'TVCG2019',
      award: '',
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://chenzhutian.org/marvist/'},
        {name: 'PDF', fileLink: MARVisTPaperUrl},
        {name: 'Demo Video', fileLink: './xxxxxx'},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ]
    },
    {
      category: [paperCategory.ALL, paperCategory.IS],
      teaser: urbanARUrl,
      title: 'Exploring the design space of immersive urban analytics',
      authorList: ['Zhutian Chen', 'Yifang Wang', 'Tianchen Sun', 'Xiang Gao', 'Wei Chen', 'Zhigeng Pan', 'Huamin Qu', 'Yingcai Wu'],
      venue: 'Visual Informatics',
      venueShort: 'VA2017',
      award: '',
      suppMaterialList: [
        // {name: 'Project Page', fileLink: './xxxxxx'},
        {name: 'PDF', fileLink: urbanARPaperUrl},
        // {name: 'Demo Video', fileLink: './xxxxxx'},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ]
    },
  ],
  newsPage: [
    {date: 'Feb. 2025', content: '💼 I was invited to give a research talk at Social Computing Group at Microsoft Research Asia (MSRA). Many thanks to Haotian Li for the invitation!', location: 'Online', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2025], link: ['https://www.microsoft.com/en-us/research/lab/microsoft-research-asia/', 'https://www.microsoft.com/en-us/research/people/xingx/', 'https://haotian-li.com/'], img: []}, 
    {date: 'Jan. 2025', content: '🎉 Two of our papers are accepted by ISEA2025! More details come later.', location: 'South Korea', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2025], link: ['https://isea2025.isea-international.org/about/theme/'], img: []},
    {date: 'Dec. 2024', content: '🎉 Our paper "InclusiViz: Visual Analytics of Human Mobility Data for Understanding and Mitigating Urban Segregation" has been accepted by PacificVis 2025 (TVCG Journal Track)!', location: 'Taipei, Taiwan', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://pacificvis2025.github.io/pages/index.html'], img: []},
    {date: 'Oct. 2024', content: '🧑🏻‍🎨 We will be holding an art exihibition titled "Altering Nature: Exploring Life in Computational Art". Exhibition Opening: Oct. 30th, 2024 @15:00-17:00 (CST). Welcome to join us!', location: '@Ping Yuan Andkinmay W Tang Gallery, HKUST, Hong Kong.', category: [newsCategory.ALL, newsCategory.IN2024], link: ['https://ais.hkust.edu.hk/whats-happening/events/altering-nature-exploring-life-computational-art', 'https://library.hkust.edu.hk/exhibitions/computational-art/', 'https://entrepreneurs.hk/content/2024/10/19/altering-nature-exploring-life-in-computational-art-a-new-era-of-arttech-and-curation/'], img: [computationalArtExhibitionUrl]}, 
    {date: 'Sept. 2024', content: 
      <span>💬 I interviewed Amy Cesal, one of the co-founders from <a href="https://www.datavisualizationsociety.org/">Data Visualization Society (DVS)</a>, and Daria Koshkina, a data visualization research specialist from <a href="https://barabasilab.com/">The BarabasiLab</a>, as our special guests for our VISAP 2024 panel "What Do Visualization Art Projects Bring to the VIS Community?" Welcome to join us on Wednesday, Oct 16th, 2024 @07:30–08:45am (EDT) </span>, 
      location: 'Online', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: ['https://ieeevis.org/year/2024/program/session_panel1.html'], img: []}, 
      
    {date: 'July. 2024', content: '🎉 Our paper "InclusiViz: Visual Analytics of Human Mobility Data for Understanding and Mitigating Urban Segregation" has been accepted as a poster for ChinaVis 2024! ', location: 'Hong Kong, China', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: [InclusionVizPaperUrl], img: []}, 
    {date: 'July. 2024', content: '🎉 Our submission "What Do Visualization Art Projects Bring to the VIS Community?" has been accepted as a panel for VIS 2024! More details come later. Looking forward to meeting you in Florida!', location: 'Florida, USA', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: ['https://shuxinhuan.github.io/visap-panel/'], img: []}, 
    {date: 'July. 2024', content: '💼 I was invited to give a research talk entitled "Visualization and Data Intelligence in the Era of Digital Humanities: Case Studies in Quantitative History and Aesthetic Appreciation" @Peking University, 2024 PKU International Visualization Summer School. Many thanks to Prof. Xiaoru Yuan for the invitation!', location: 'Online, China', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: ['https://chinavis.org/s24/index_en.html'], img: []}, 
    {date: 'July. 2024', content: '🗣 I attended the IC2S2 (International Conference on Computational Social Science) conference and present our paper InnovationInsights.', location: 'Philadelphia, USA', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://ic2s2-2024.org/'], img: []},
    {date: 'July. 2024', content: '🗣 I attended the ICSSI (International Conference on the Science of Science & Innovation) conference and present a working paper.', location: 'National Academy of Sciences, DC, USA', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: ['https://www.icssi.org/2024-schedule'], img: []}, 
    {date: 'May. 2024', content: '💼 I was invited to give a research talk entitled "Visual Data Intelligence for Quantitative History and Aesthetic Appreciation in the Age of Digital Humanities" to The School of Art and Archaaeology @Zhejiang University. Many thanks to Prof. Tan Tang for the invitation!', location: 'Online, China', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: ['http://www.soaa.zju.edu.cn/main.htm'], img: []}, 
    {date: 'Apr. 2024', content: '🧑🏻‍🎨 One of our artwork has been accepted by SIGGRAPH 2024 Art Gallery!! More details come later. Looking forward to meeting you in Denver, Colorado!', location: 'Denver, USA', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://s2024.siggraph.org/exhibit-at-siggraph/'], img: []}, 
    {date: 'Apr. 2024', content: '💼 I was invited to give a research talk entitled "Data-Driven Human-Machine Intelligence for Human and Social Dynamics" to The MU Collective Group (directed by Jessica Hullman and Matthew Kay) @Northwestern University. Many thanks to Fumeng Yang and Ziyang Guo for the invitation!', location: 'Evanston, USA', category: [newsCategory.ALL, newsCategory.TALK, newsCategory.IN2024], link: ['https://mucollective.northwestern.edu/'], img: []}, 
    {date: 'Apr. 2024', content: '🗣 One of our paper has been invited to a contributed talk @IC2S2 (International Conference on Computational Social Science) in July! More details come later.', location: 'Philadelphia, USA', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://ic2s2-2024.org/'], img: []}, 
    {date: 'Apr. 2024', content: '🗣 One of our paper has been invited to a contributed talk @ICSSI (International Conference on the Science of Science & Innovation) in July! More details come later.', location: 'National Academy of Sciences, USA', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://www.icssi.org/2024-schedule'], img: []}, 
    {date: 'Feb. 2024', content: '🧑🏻‍🎨 Our paper "Hidden Incidents of Pet Food: Portraying Taurine Deficiency in Cats" is accepted by ISEA2024 (the top conference in media art)!', location: '', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://isea2024.isea-international.org/welcome/'], img: [ISEA2024Logo]}, 
    {date: 'Jan. 2024', content: '🎉 Our paper "A Comparative Study on Fixed-order Event Sequence Visualizations: Gantt, Extended Gantt, and Stringline Charts" is accepted by IEEE TVCG!', location: '', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2024], link: ['https://osf.io/qdpzm/']}, 
    {date: 'Nov. 2023', content: '🧑🏻‍🎨 Our data artwork "Posts with no Response: The Island of Loneliness" has been exihibited at Information+ Conference 2023!', location: 'Edinburgh', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: ['https://informationplusconference.com/2023/#exhibition'], img: [InfoPlus23]}, 
    {date: 'Oct. 2023', content: '🗣 Our paper "InnovationInsights: A Visual Analytics Approach for Understanding the Dual Frontiers of Science and Technology" has been presented at IEEE VIS2023!', location: 'Melbourne', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: ['https://kellogg-cssi.github.io/InnovationInsights/'], img: [VIS23InnovationInsightsA, VIS23InnovationInsightsB]}, 
    {date: 'Oct. 2023', content: '🧑🏻‍🎨 Our data artwork "Posts with no Response: The Island of Loneliness" has been exihibited @VISAP2023 at IEEE VIS2023!', location: 'Melbourne', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: ['https://visap.net/2023/'], img: [VISAP23A, VISAP23B]}, 
    {date: 'Oct. 2023', content: '🧑🏻‍🎨 Our "China in Maps" project with HKUST Library is finally coming to life with an exhibition opening ceremony at HKUST!', location: 'Hong Kong', category: [newsCategory.ALL, newsCategory.MEDIA, newsCategory.IN2023], link: ['https://library.hkust.edu.hk/china-in-maps/'], img: [chinainmaps]}, 
    {date: 'Sept. 2023', content: '🧑🏻‍🎨 Three of our data-driven artworks have been longlisted by 2023 IIB Awards (Information is Beautiful Awards)!!!', location: '', category: [newsCategory.ALL, newsCategory.AWARDS, newsCategory.IN2023], link: [
        'https://www.informationisbeautifulawards.com/showcase/6355-posts-with-no-response-the-island-of-loneliness',
        'https://www.informationisbeautifulawards.com/showcase/6609-hidden-incidents-of-pet-food-melamine-adulteration',
        'https://www.informationisbeautifulawards.com/showcase/6614-hidden-incidents-of-pet-food-pentobarbital-secondary-poisoning',
    ], img: [IIBLogo]},
    {date: 'Aug. 2023', content: '🏆 Our paper "InnovationInsights: A Visual Analytics Approach for Understanding the Dual Frontiers of Science and Technology" has won Best Paper Honorable Mentions @IEEE VIS2023!!!', location: '', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.AWARDS, newsCategory.IN2023], link: ['https://ieeevis.org/year/2023/info/awards/best-paper-awards']},
    {date: 'Aug. 2023', content: '🎉 Our paper "NeighViz: Towards Better Understanding of Neighborhood Effects on Social Groups with Spatial Data" has been accepted by VDS2023@IEEE VIS!! More details to come later.', location: '', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: ['https://www.visualdatascience.org/2023/']},
    {date: 'Jul.-Aug. 2023', content: '🧑🏻‍🎨 Our data art paper "Posts with no Response: The Island of Loneliness" has been accepted by VISAP2023 (both Pictorial and Artwork tracks) and Information+ conference! Cannot wait to meet all friends in Melbourne and Edinburgh!', location: '', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: [LonelyIslandVideoUrl]},
    {date: 'Jun. 2023', content: '🎉 Two of our papers have been accepted by the VIS2023 conference! Cannot wait to meet all friends in Melbourne!', location: '', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: ['https://ieeevis.org/year/2023/welcome']},
    {date: 'Jun. 2023', content: '🎉 Our paper "InnovationInsights: A Visual Analytics Approach for Understanding the Dual Frontiers of Science and Technology" has been accepted as a poster and been presented at the ICSSI2023 conference!', location: 'Evanston', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.IN2023], link: ['https://www.icssi.org/2023-schedule']},
    {date: 'Nov. 2022', content: '🧑🏻‍🎨 Our data art work was reported by one of the mainstream media in China "澎湃美术课": "求求你，文字别再密密麻麻地排了"', location: 'Online', category: [newsCategory.ALL, newsCategory.MEDIA, newsCategory.BEFORE2023], link: ['https://mp.weixin.qq.com/s/3GJ11TLT9YALqaJ9hQ5TmA']},
    {date: 'Jan. 2022', content: '🧑🏻‍🎨 Our data art work was reported by a design and innovation Wechat Official Account "一只梨": "给数据来点儿设计：数据可视化的N种可能性"', location: 'Online', category: [newsCategory.ALL, newsCategory.MEDIA, newsCategory.BEFORE2023], link: ['https://mp.weixin.qq.com/s/-sbNslBgCN5hHGnStHAhHQ']},
    {date: 'Oct. 2022', content: 'I attended VIS 2022 (The IEEE Visualization Conference) in Oklahoma! This is the first time I attended an onsite conference!', location: 'Virtual', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.TALK, newsCategory.BEFORE2023]},
    {date: 'Oct. 2022', content: '🧑🏻‍🎨 Our data-driven art work "Explore Mindfulness without Deflection: A Data Art Based on the Book of Songs" has been longlisted in Information is Beautiful Award 2022!', location: 'Evanston', category: [newsCategory.ALL, newsCategory.BEFORE2023], link: ['https://www.informationisbeautifulawards.com/showcase/5762-explore-mindfulness-without-deflection-a-data-art-based-on-the-book-of-songs'], img: [IIBLogo]},
    {date: 'Sept. 2022', content: '🛫 I joined CSSI Lab at Northwestern University!', location: 'Evanston', category: [newsCategory.ALL, newsCategory.BEFORE2023], img: [cssiLogo]},
    {date: 'Aug. 2022', content: '🎓 I graduated from HKUST as a CSE PhD!', location: 'Hong Kong', category: [newsCategory.ALL, newsCategory.BEFORE2023]},
    {date: 'Aug. 2022', content: '🧑🏻‍🎨 Our data-driven art work "Hidden Incident of Pet Food" has been exhibiteed on the 1st CMA International Symposium and Exhibition!', location: 'Hong Kong', category: [newsCategory.ALL, newsCategory.BEFORE2023], link: ['https://electricdreams.ust.hk/hidden-incident-of-pet-food/']},
    {date: 'Aug. 2022', content: '🧑🏻‍🎨 Our data-driven art work "Explore Mindfulness without Deflection: A Data Art Based on the Book of Songs" has been exhibiteed on the 1st CMA International Symposium and Exhibition!', location: 'Hong Kong', category: [newsCategory.ALL, newsCategory.BEFORE2023], link: ['https://electricdreams.ust.hk/explore-mindfulness-without-deflection-a-data-art-based-on-the-book-of-songs/']},
    {date: 'Jun. 2022', content: '🏆 I won HKUST RedBird Academic Excellence Award!', location: 'Hong Kong', category: [newsCategory.ALL, newsCategory.AWARDS, newsCategory.BEFORE2023]},
    {date: 'Jun. 2022', content: '🗣 I was invited to ACM SIGGRAPH SPARKS, a project of the DIGITAL ARTS COMMUNITY (DAC), ACM SIGGRAPH, and presented our data art work about the Book of Songs', location: 'Virtual', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.TALK, newsCategory.BEFORE2023], link: ['https://dac.siggraph.org/sparks-jun2022/'], img: [sparksImg]},
    {date: 'Oct. 2021', content: '🗣 I attended VISAP2021 @VIS 2021 (The IEEE Visualization Conference), presented 1 art paper', location: 'Virtual', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.TALK, newsCategory.BEFORE2023]},
    {date: 'Oct. 2021', content: '🗣 I attended VIS 2021 (The IEEE Visualization Conference), presented 2 full papers', location: 'Virtual', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.TALK, newsCategory.BEFORE2023]},
    {date: 'May 2021', content: '🗣 I visited FDUVIS, and presented my research about CareerLens', location: 'Sahnghai, China', category: [newsCategory.ALL, newsCategory.VISITS, newsCategory.TALK, newsCategory.BEFORE2023]},
    {date: 'Apr. 2021', content: '🗣 I was invited by CVM 2021 (The International Conference on Computational Visual Media), and presented my research about CareerLens', location: 'Qingdao, China', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.TALK, newsCategory.BEFORE2023]},
    {date: 'Aug. 2020', content: 'ChinaVIS 2020 (China Visualization and Visual Analytics Conference)', location: 'Xi-an, China', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.BEFORE2023]},
    {date: 'Aug. 2019', content: 'CIPS-SMP (Social Media Processing Conference)', location: 'Shenzhen, China', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.BEFORE2023]},
    {date: 'Oct. 2017', content: '🏆 I won He Zhijun Scholarship in College of Computer Science and Technology (top 10) @ Zhejiang University', location: 'Hangzhou, China', category: [newsCategory.ALL, newsCategory.AWARDS, newsCategory.BEFORE2023]},
    {date: 'Oct. 2017', content: '🏆 I went to CNCC (China National Computer Congress) and was awarded as The CCF (China Computer Federation) Elite Collegiate Award(2017)', location: 'Fuzhou, China', category: [newsCategory.ALL, newsCategory.CONFERENCE, newsCategory.AWARDS, newsCategory.BEFORE2023]},
  ],
  dataArtPage: [
    {
      category: [paperCategory.ALL, paperCategory.DR],
      teaser: lossGenerationUrl,
      award: [dataArtAwardCategory.SIGGRAPHART],
      year: 2024, 
      title: 'Loss of Sonnet 18', 
      titleShort: 'Loss of Sonnet 18',
      classification: 'PHILOSOPHICAL REFLECTION', 
      theme: ['digital world'], 
      authorList: ['Junxiu Tang', 'Jiayi Zhou', 'Yifang Wang', 'Xinhuan Shu', 'Peiquan Xia', 'Xiaojiao Chen', 'Tan Tang', 'Yingcai Wu'],
      venue: 'SIGGRAPH 2024, Art Gallery',
      venueShort: 'SIGGRAPH Art 2024',
      mediaCoverage: [], 
      introduction: <div>
        <p>
          This project is an explorative digital art installation that delves into the phenomenon of generation loss - the inevitable degradation of information (e.g., words) quality through copying and propagation. It transcends the technical realm to probe how this concept applies to the transmission and transformation of words and meanings through time and technology. 
        </p>
        <p>
          It consists of a series of videos illustrating the loss of words themselves and their meaning, combined with real-time interpretation from both humans and machine. The project allows the audience to find a border of loss, beyond which, information of words might change.
        </p>

        <br />
        <h4>Conceptual Foundation</h4>
        <p>At the heart of "Loss of Sonnet 18" lies an acknowledgment of the inherent loss in information storage and spreading, echoing through the corridors of human history and our methods of preserving and transmitting words. Although there is an inherent loss in information transfer, the thought behind information (i.e., the “word” in this project context) storage hardly changes.</p>
        <p>Depicting the everlasting beauty, regardless of any loss, Shakespeare’s Sonnet 18 serves as our inspiration, a poetic testament to the fleeting nature of beauty and the supposed immortality bestowed through verse. Yet, this project asks a poignant question: what happens when the medium of this immortality - the words themselves - undergo generational loss?</p>
        <p>We try to describe the loss of information through time and technique in an intuitive way, and invite the audience to feel both the changing and unchanging of thought behind different iterations of storage and spreading.</p>

        <br />
        <h4>Artistic Interpretation and Process</h4>
        <p>Through a blend of traditional poetry and modern technology, we simulate the blurring and compression of text, such as the distortion experienced in social media and digital communication.</p>
        <p>In a nod to the pre-digital era, we present these texts upon images, like the visual nature of historical mediums written expression – ink on paper, prints, etchings on stone, all of which can be considered as images. This deliberate juxtaposition illuminates the transformative journey of words and meanings, guiding the audience through an experiential narrative where the known gradually morphs into the enigmatic.</p>
        <p>Through this artistic process, we invite the audiences to become witnesses to the subtle yet profound metamorphosis of language. They will observe familiar words and phrases slowly erode into realms of ambiguity and reinterpretation, and try to find a border of recognizable loss within this process.</p>

        <br />
        <h4>Inspirations and Technical Aspects</h4>
        <p>Drawing from the pioneering works of Ellie Pritts (project Loss), David Elliott (JPEG compression for 600 times), Alvin Lucier (I'm Sitting in a Room), and Dietmar Offenhuber (Autographic Visualization), "Loss of Sonnet 18" represents a synthesis of art, data, and technology. Specifically, we focus on the generation loss of text data through image medium. We choose the last sentence of Shakespeare's Sonnet 18, “So long as men can breathe or eyes can see, So long lives this, and this gives life to thee,” as the main text content in our piece.</p>
        <p>The major medium of "Loss of Sonnet 18" is a series of videos that record the loss of words being stored and spread. Each video within this artwork is a sequence of 999 images, arranged according to their respective compression iterations. In each iteration, we program to add different types of noises to deliberately degrade the image quality and infuse it with a touch of blur, simulating the gradual erosion of clarity and precision.</p>
        <p>This iterative process is cumulative; the output of one iteration becomes the input for the next, creating a layered narrative of loss. Each subsequent image is not just a standalone representation but an aggregate of all the preceding levels of distortion and decay. This continuous progression encapsulates the core theme of our project - the relentless, incremental diminishment of data integrity, mirroring the inexorable passage of time and the transformative nature of memory and perception that might occur on words.</p>
        <p>The background sounds in videos consist of two parts. One is the morse code of the poem “So long as men can breathe or eyes can see, So long lives this, and this gives life to thee.” It is an echo of machine signals, in contrast to the sound in the audience’s brain, i.e., the echo of human thought. The other is environmental music we subjectively select to match the main idea of this project. With loss deepening, the background sound also becomes distorted and noisy, generating a kind of sound and picture synesthesia feeling.</p>
        <p>We utilize Python with the Pillow library for image processing and Microsoft Azure services for Optical Character Recognition (OCR) and computer vision. We render the video presentation through an HTML5 application.</p>

        <br />
        <h4>Broader Implications and Conclusion</h4>
        <p>"Loss of Sonnet 18" invites contemplation on the essence of communication in our digital age. It raises questions about the fidelity of text in electronic mediums and draws parallels with ancient methods of text preservation. The project underscores the perpetual yet constantly evolving nature of loss, leaving an indelible impression of fleeting beauty and the ephemeral nature of understanding.</p>
        <p>Beyond the words in "Loss of Sonnet 18," we present not just an art piece but a profound commentary on the transient nature of data and meaning. It’s an invitation to experience, ponder, and engage with the ever-shifting landscape of communication, technology, and human perception.</p>

      </div>, 
      story: 
        <Tabs defaultActiveKey="1">
          <TabPane tab="English" key="1">
            <div>
                <blockquote className='quote'>So long as men can breathe or eyes can see, So long lives this, and this gives life to thee. -- Shakespeare, Sonnet 18</blockquote>
                <blockquote className='quote'>The ship wherein Theseus and the youth of Athens returned from Crete had thirty oars, and was preserved by the Athenians down even to the time of Demetrius Phalereus, for they took away the old planks as they decayed, putting in new and stronger timber in their places, insomuch that this ship became a standing example among the philosophers, for the logical question of things that grow; one side holding that the ship remained the same, and the other contending that it was not the same. -- Plutarch, Life of Theseus 23.1</blockquote>
                <p>Vaguely, I remember the beginning of it all—it stemmed from a "watermark." Memes, photos, videos—shared and reshared across the internet, layer upon layer, becoming increasingly pixelated, weighed down by watermark after watermark. "Ancestral watermarks," as they’re often mockingly called. We laugh at the notion, but something more seems to lie hidden within, a deeper story to be unearthed.</p>
                <p><strong>Forgetting. </strong>This appears to be a new kind of forgetting, a multimodal one native to the internet age. The rise of cloud databases has granted us the ability to upload and share vast amounts of information. Yet, in this process, as these data pass through multiple channels, subject to space limitations, copyright constraints, they become blurred, cropped, and marked. Information is degraded layer by layer until it is nearly unrecognizable. Despite the storage capabilities of cloud databases, the sense of losing something essential, of forgetting, still lingers.</p> 
                <p><strong>Reinterpretation. </strong>With each step in the transmission and sharing of data, some information isn’t just lost; it’s reinterpreted, giving rise to something new. This phenomenon is fascinating, and three scenarios come to mind:</p>
                <li>Rumors, or misinformation. Even with mere words—without the complexity of multiple modes—meanings shift as they are passed from person to person.</li>
                <li>Annotations. I recall a discussion during my PhD with Professor Qu about how different commentaries, written by authors from various eras, annotate the same text collection. This temporal evolution is quite intriguing.</li>
                <li>Restoration of artifacts. Take, for example, the restoration of Dunhuang murals. This could be considered the “reverse engineering” of generation loss—where later interpreters attempt to recover the original appearance of artifacts.</li>
                <li>...</li>
                <br />
                <p>Generation loss, and loss generation—this Ship of Theseus-like cycle of iteration and renewal is a common phenomenon in the era of big data. It is fascinating—new interpretations are constantly generated; it is perilous—rumors and misinformation are born from it; it is sorrowful—because even with enormous data storage capabilities, loss and forgetting persist, breaking the illusion of what we once hoped would be eternal.</p>

                <p>2024, Year of the Wood Dragon – Beginning of Autumn</p>
                <br />
              </div>          
          </TabPane>
          <TabPane tab="中文" key="2">
            <div>
              <blockquote className='quote'>So long as men can breathe or eyes can see, So long lives this, and this gives life to thee. -- Shakespeare, Sonnet 18</blockquote>
              <blockquote className='quote'>The ship wherein Theseus and the youth of Athens returned from Crete had thirty oars, and was preserved by the Athenians down even to the time of Demetrius Phalereus, for they took away the old planks as they decayed, putting in new and stronger timber in their places, insomuch that this ship became a standing example among the philosophers, for the logical question of things that grow; one side holding that the ship remained the same, and the other contending that it was not the same. -- Plutarch, Life of Theseus 23.1</blockquote>
              <p>模糊的记得，事情的起因是来自“水印”。来自互联网的、不断被人转发的表情包也好、照片和视频也好，随着时间的流传，最直接的结果就是像素越来越低、堆叠了越来越厚的水印。“祖传的水印”--这是一种经常能从网友们那里听到的戏谑的叫法。一笑而过之后，我们却感觉到了一些额外的可以探究的故事。</p>
              <p><strong>遗忘。</strong>这似乎是互联网下一种新型的多模态式的遗忘。云端数据库的出现，让我们能够有机会把海量的信息上载和分享。但是在这过程中，因为一些转载过程中空间和版权的限制，这些数据开始被模糊、裁剪、加上水印。信息的传达在层层加码的过程中不断被损耗，直至无法分辨... 大容量云端数据库的存在，使我们原本觉得我们可以清晰的记录下生活的每一分钟，然而，遗忘，依旧存在。</p> 
              <p><strong>重新解读。</strong>在这些层层加码的数据传递和分享中，信息不仅仅是损失，更有一部分模糊的信息被重新解读，产生了新的信息。这种现象也十分有意思，我的脑海中首先想起的是3个类似的场景：</p>
              <li>（一）谣言，or misinformation。即便是语言（而非多模态），在口口相传中，语义也会发生变化。</li>
              <li>（二）注疏。我记得读PhD的时候，和屈老师讨论idea的时候他提到一个现象：同一本文集在不同朝代不同后人作家手中的注解--注释和再注释。这种temporal evolution也十分有意思。</li>
              <li>（三）文物修复。例如，敦煌壁画修复。这可以说是generation loss的“逆向工程”--通过后人对文物的解读，视图恢复文物的原貌。</li>
              <li>...</li>
              <br />
              <p>Generation loss, and loss generation -- 这种忒修斯之船（Ship of Theseus）式的迭代和更新，是大数据时代普遍存在的现象。他是有趣的--新的解读正在生成；他是危险的--谣言也因此而来；他是悲伤的--即便是拥有海量的数据存储资源的情况下，loss和遗忘依旧在发生，打破了我们想保留的所谓的“永恒”...</p>

              <p>2024甲辰年·立秋</p>
              <br />
            </div>
          </TabPane>
        </Tabs>, 
      imageList: LossGenerationImgUrlList, 
      suppMaterialList: [
        {name: 'PDF', fileLink: 'https://dl.acm.org/doi/10.1145/3641523.3669939'},
        {name: 'Official Website', fileLink: 'https://s2024.conference-program.org/presentation/?id=artg_205&sess=sess284'},
        {name: 'Demo Video', fileLink: lossGenerationVideoUrl},
        {name: 'Slides', fileLink: generationLossSlidesUrl}, 
      ],
      detailPageData: {
        exihibitionImageInfo: {
          image: LossGenerationImgUrlList[1],
          time: 'Jul 28, 2024 – Aug 1, 2024', 
          title: 'Loss of Sonnet 18',
          description: <>
          <p>
            This project is an explorative digital art installation that delves into the phenomenon of generation loss - the inevitable degradation of information (e.g., words) quality through copying and propagation. It transcends the technical realm to probe how this concept applies to the transmission and transformation of words and meanings through time and technology. 
          </p>
          <p>
            It consists of a series of videos illustrating the loss of words themselves and their meaning, combined with real-time interpretation from both humans and machine. The project allows the audience to find a border of loss, beyond which, information of words might change.
          </p>
        </>, 
        }, 
        secondaryImageInfo: {
          image: LossGenerationImgUrlList[0],
          title: 'Background',
          description: <div>
            <p className='description'>In analogue recordings, a progressive loss of quality that occurs every time a tape, film, or vinyl disc is copied. The problem of generation loss has been reduced but not eradicated in digital media. <span>— A Dictionary of Media and Communication (1 ed.), 2011 </span></p>
            <p className='description'>Generation loss is the loss of quality between subsequent copies or transcodes of data. <span>— Wikipedia. <a href='https://en.wikipedia.org/wiki/Generation_loss'>Generation Loss</a>.</span></p>
          </div>
        }, 
        awardImageInfo: {
          image: LossGenerationImgUrlList[5],
          awardLogoList: LossGenerationAwardLogoList
        }
      }
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.DR],
      teaser: CatVisUrl,
      award: [dataArtAwardCategory.ISEA2024, dataArtAwardCategory.LumenPrize2024, dataArtAwardCategory.IIB2023],
      year: 2024, 
      title: 'Hidden Incidents of Pet Food: Portraying Taurine Deficiency in Cats',
      titleShort: 'Hidden Incidents of Pet Food',
      classification: 'PROTEST AND CRITIQUE', 
      theme: ['Alife'], 
      authorList: ['Ziwei Wu', 'Xiaofu Jin', 'Danlu Fei', 'Yifang Wang', 'Guangda Zhu', 'Kang Zhang'],
      venue: 'The International Symposium on Electronic Art (the top conference in media art)',
      venueShort: 'ISEA2024',
      mediaCoverage: [
        {name: 'Entrepreneurs.hk', title: 'Altering Nature: Exploring Life in Computational Art -- A New Era of ArtTech and Curation', fileLink: 'https://entrepreneurs.hk/content/2024/10/19/altering-nature-exploring-life-in-computational-art-a-new-era-of-arttech-and-curation/'}, 
        {name: 'Division of Emerging Interdisciplinary Areas (EMIA) and the Division of Arts and Machine Creativity (AMC) at HKUST', title: 'Altering Nature: Exploring Life in Computational Art', fileLink: 'https://library.hkust.edu.hk/exhibitions/computational-art/'}, 
        {name: '香港科技大学快讯', title: '香港校园：信息枢纽｜双校联动办展！在计算艺术中探索生命', fileLink: 'https://mp.weixin.qq.com/s/_EPj8wKEdIY3V7rbbDbVgw'}, 
        {name: 'Lumen Prize 流明艺术项目 (英国流明艺术科技奖中国官方机构)', title: '流明学生奖艺术家动态 吴子薇 刘娃 孙羽茜', fileLink: 'https://mp.weixin.qq.com/s/k3z4jKViVgU6LWRxO0Aj8g'}, 
      ], 
      introduction: <div>
        <p>
          "Hidden Incidents of Pet Food" is a digital artwork that combines contemporary art, machine learning, and data storytelling. It aims to reveal a relatively unknown yet significant social issue, specifically the deficiency of taurine in cat food. Leveraging Artificial Intelligence, the authors generate digital portraits of 6,000 cats who died from this deficiency. Incorporating additional data from the documented history, the authors reveal the hidden incidents through visualization and data storytelling, showing the trending relationship between the boom of pet capital markets and the deaths of cats due to pet food problems. The AI-generated cat portraits and incident data go beyond the facts and could become a historical memorial for blind cats and those tragic fatalities linked to pet foods. Our computational approach reveals the questionable relationship between people and pets as controlled by capitalism.
        </p>
        {/* <p>《宠物食品中的隐藏事件》Hidden Incidents of Pet Food 是一个交互数字艺术作品，结合了当代艺术、机器学习和数据叙事。艺术家利用人工智能生成了6000只死于猫粮中牛磺酸缺乏的猫的数字肖像，通过整合历史文献中的额外数据，她通过可视化和数据叙事揭示了这些隐藏的事件，展示了宠物资本市场蓬勃发展与由于宠物食品问题导致的猫死亡之间的关系趋势。AI生成的猫的肖像和事件数据超越了事实本身，可能成为对那些受宠物食品问题影响而导致悲剧性死亡的猫的历史纪念。</p>
        <p>艺术家的计算方法揭示了资本主义控制下人与宠物之间值得反思的关系。</p> */}
      </div>, 
      story: 
      <Tabs defaultActiveKey="1">
          <TabPane tab="English" key="1">
          <div>
              <h4>Prologue</h4>
              <p>Ziwei’s cat has passed away.</p>
              <p>Upon investigation, it was likely due to a questionable additive in a certain brand of cat food. Although this additive is not legally banned, numerous cases have shown that it can cause severe health issues, even death, in cats. In a series of struggles between individuals and corporations, the weaker side always loses.</p>
              <p><span style={{ borderBottom: '1px dotted #3F3F3F' }}>So, we choose to speak through art.</span></p>

              <br />
              <h4>The Artwork</h4>
              <p>This piece is part of a series that explores the damage to life caused by the absence or presence of three specific chemical elements in pet food and even human food. At ISEA 2024, we are presenting the first of these elements—taurine.</p>
              <p>In 1987, researchers from the University of California, Davis, published an article in Science that discussed the link between taurine and feline diseases. They noted that “feeding cats a taurine-deficient diet results in reduced plasma and tissue taurine concentrations, including in the retina and myocardium. Taurine deficiency lasting over six months can lead to feline central retinal degeneration (FCRD) and dilated cardiomyopathy (DCM).” They estimated that in the United States, at least 1,000 cats die each year due to a lack of taurine in pet food.</p>
              <p>Canned pet food began mass production in the United States in 1922, and since then, it’s impossible to imagine or track the number of cats and other pets that have died due to taurine deficiency. It wasn’t until 1976 that studies began to examine the relationship between taurine and feline retinal health.</p>
              <p>However, even with this research, there were no specific regulations on taurine levels in cat food at the time. In 1981, the National Research Council finally released its recommendations, stating that cat food should contain at least 500 ppm of taurine. Unfortunately, the taurine levels in commercial cat food were far below this recommendation. Given the late establishment of this standard, it’s estimated that between 1976 and 1981 alone, at least 6,000 cats died from taurine deficiency. Even after 1981, when these recommendations were widely publicized, questions remained about whether pet food manufacturers complied with the policy and whether violators were penalized.</p>
              <p>Despite our desire to reach further back in time, the lack of reliable data restricts us to sample from the known period between 1976 and 1981. Based on this information, we have generated images to commemorate the innocent feline lives lost during this period.</p>

              <p>We used AI to generate portraits of 6,000 cats. These cats all perished due to taurine deficiency in their food. Through this work, we aim to grant their lives a semblance of “eternity.” We reveal these hidden events using data storytelling, highlighting the trend between the booming pet food industry and the deaths of cats due to pet food issues.</p>

              <br />
              <h4>Afterword</h4>
              <p>As a data analyst, I have never encountered a project where data collection was so challenging. The number of incidents was far fewer than we imagined. Most events involving deaths due to pet food problems were not recorded in detail or reported; at best, we found sporadic investigative reports or news estimates. We suspect this is due partly to the lack of a well-established platform or institution to document these events. Moreover, it chills the heart to consider the helplessness of individual pet owners when facing massive corporations and economic interests—how many incidents had a chance to be exposed but were instead buried by capital? We may never know. This posed a significant obstacle to our initial intent to “create a digital art piece.” Thus, we turned to AI to generate virtual pet images and models, expanding the original data reasonably, enabling the current presentation.</p>
              <p>This remains an ongoing project. We still hope to call more people to pay attention to and even get involved in the issue of food safety for pets and humans alike.</p>

              <br />
              <p>2024, Year of the Wood Dragon – Beginning of Autumn</p>
              <br />
              {/* 没有数据，形成incident的数据需要7步。资本的博弈。几乎没有公开数据。联系法医等。整个过程十分艰难。 */}
            </div>
          </TabPane>
          <TabPane tab="中文" key="2">
            <div>
              <h4>楔子</h4>
              <p>子薇的猫猫去世了。</p>
              <p>经过检测，其中的原因很有可能是来自于某个品牌猫粮的可疑的食品添加剂。这种添加剂并没有被法律命令禁止添加，但确实有很多个案显示猫猫吃了以后会出现问题甚至去世。一系列个体和资本的博弈，都以势弱的个体方失败而告终。</p>
              <p><span style={{ borderBottom: '1px dotted #3F3F3F' }}>所以，我们选择，用艺术发声。</span></p>

              <br />
              <h4>作品</h4>
              <p>这是一个系列作品，呈现了包括牛磺酸在内的三种化学元素在宠物甚至人类食品中的缺失或者添加而导致的生命的受损甚至陨落。ISEA 2024呈现的是第一种化学物质--牛磺酸。</p>
              <p>1987年，加利福尼亚大学戴维斯分校的研究人员在《科学》杂志上发表了一篇文章，探讨了牛磺酸与猫类疾病之间的关系，指出“给猫喂食缺乏牛磺酸的食物会导致血浆和组织中牛磺酸浓度降低，包括视网膜和心肌。牛磺酸缺乏超过六个月可能会引起猫的中心性视网膜退行性变（FCRD）和扩张型心肌病（DCM）”。他们还估计，每年美国至少有1000只猫因宠物食品中缺乏牛磺酸而死亡。</p>
              <p>如前所述，罐装宠物食品于1922年在美国开始大规模生产，自那时起，因缺乏牛磺酸而死亡的猫和其他宠物的数量是无法想象和追踪的。直到1976年，研究才开始探讨牛磺酸与猫视网膜健康之间的关系。</p>
              <p>然而，即使有了相关研究，那个时代的猫粮中牛磺酸含量仍没有明确的规定。1981年，美国国家研究委员会终于发布了他们的推荐标准，指出猫粮应至少含有500ppm的牛磺酸。不幸的是，当时市售猫粮中的牛磺酸含量远低于这个推荐水平。此外，由于这个推荐标准建立的时间较晚，按照1987年发表的论文计算，在1976年至1981年的六年间，至少有6000只猫因牛磺酸缺乏而死亡。更令人沮丧的是，即使在1981年之后，当有关猫粮中牛磺酸含量的规定被广泛宣传后，相关的宠物食品制造商是否遵守了政策，是否对违反规定的行为进行了处罚，仍然存有疑问。</p>
              <p>尽管我们希望追溯到更早的时间，但由于缺乏更可靠的数据和资源，我们只能取样已知的1976年至1981年这六年期间，估算因牛磺酸缺乏导致的猫死亡情况。基于这些信息，我们生成了一些图像，以纪念在这一时期无辜逝去的猫猫的生命。</p>

              <p>我们用AI生成了6000只猫的肖像。这些猫，都是在猫粮中死于牛磺酸缺乏的。我们通过这种方式，试图让这些猫的生命得到纪念，让这些猫的生命得到一种“永恒”。我们用数据叙事的方式，揭示了这些隐藏的事件，展示了宠物资本市场蓬勃发展与由于宠物食品问题导致的猫死亡之间的关系趋势。</p>

              <br />
              <h4>后记</h4>
              <p>作为一个data analyst, 我从没有遇到过一个收集数据如此困难的项目。Incident数量之少，超乎我们的想象。大量的死于宠物食品问题的事件并没有被详细记录和报道，最多只有零星几篇调查报告或者新闻中的预估总量。我们不得不怀疑，一方面是因为没有一个well-established的平台和机构来记录这些事件；另一方面，更心冷于个体宠物主人面对庞大的经济利益体和公司时候的无奈--有多少事件曾经有机会被揭发但却被资本洗白？我们不得而知。总之，这让我们一开始“做一个数字艺术作品”的初衷收到了巨大的阻碍。因此，我们不得不采用AI技术来生成一些虚拟的宠物图片和模型，对原始数据进行合理的扩充，才得到了现有的呈现。</p>
              <p>这依旧是一个正在持续的项目。我们依旧希望可以通过它，来号召更多的人关注甚至投身到宠物乃至人类食品安全的问题中。</p>

              <br />
              <p>2024甲辰年·立秋</p>
              <br />
              {/* 没有数据，形成incident的数据需要7步。资本的博弈。几乎没有公开数据。联系法医等。整个过程十分艰难。 */}
            </div>
          </TabPane>
      </Tabs>, 
      imageList: CatVisImgUrlList, 
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://behind-the-molecules.vercel.app/'},
        {name: 'PDF', fileLink: CatVisPaperUrl},
        {name: 'Demo Video', fileLink: CatVisVideoUrl},
      ], 
      detailPageData: {
        exihibitionImageInfo: {
          image: CatVisImgUrlList[6],
          time: 'Oct. 2024', 
          title: 'Hidden Incidents of Food Safety',
          description: <>
            <p>"Hidden Incidents of Pet Food: Portraying Taurine Deficiency in Cats" is a digital artwork that combines contemporary art, machine learning, and data storytelling. It aims to reveal a relatively unknown yet significant social issue, specifically the deficiency of taurine in cat food. Leveraging Artificial Intelligence, the authors generate digital portraits of 6,000 cats who died from this deficiency. Incorporating additional data from the documented history, the authors reveal the hidden incidents through visualization and data storytelling, showing the trending relationship between the boom of pet capital markets and the deaths of cats due to pet food problems. The AI-generated cat portraits and incident data go beyond the facts and could become a historical memorial for blind cats and those tragic fatalities linked to pet foods. Our computational approach reveals the questionable relationship between people and pets as controlled by capitalism.</p>
          <p>"Hidden Incidents of Animal Euthanasia" is an interactive art display that represents secondary pentobarbital poisoning among multi-species. This work combines incident data, visualization and sonification, 3D AI generative techniques, and interactivity to enable the audiences to explore the kinship of human-animal interaction under euthanasia. Moreover, it delves into the intricate dynamics of dominance and affection that define our interactions with other sentient beings, offering a platform for critical engagement and reflection. This new approach reveals a thoughtful examination of the ethical dimensions and intricacies surrounding humane euthanasia practices, particularly the usage of synthetic drug as the means to do so.</p>
          </>
        }, 
        secondaryImageInfo: {
          image: CatVisImgUrlList[0],
          title: 'Taurine Deficiency in Cat',
          description: <p className='description'>In 1987, researchers from the University of California, Davis published an article in Science on the relationship between taurine and feline diseases, stating that “feeding taurinedeficient diets to cats results in low concentrations of taurine in plasma and tissues, including the retina and myocardium. Taurine depletion for more than six months may produce feline central retinal degeneration (FCRD) and dilated cardiomyopathy (DCM)”. They also estimated that at least 1,000 cats die each year in the United States because of insufficient taurine in pet food.</p>
        }, 
        awardImageInfo: {
          image: CatVisImgUrlList[1],
          awardLogoList: CatVisAwardLogoList
        }
      }
    },
    {
      category: [paperCategory.ALL, paperCategory.CSS, paperCategory.DR],
      teaser: LonelyIslandStoryUrl,
      award: [dataArtAwardCategory.CHINAVISAP2024, dataArtAwardCategory.VISAP2023, dataArtAwardCategory.INFOPLUS, dataArtAwardCategory.IIB2023, ],
      year: '2023-2024', 
      title: 'Posts with No Response: The Island of Loneliness', 
      titleShort: 'The Island of Loneliness',
      classification: 'PHILOSOPHICAL REFLECTION', 
      theme: ['Digital World', 'Social Isolation'],  
      authorList: ['Junxiu Tang', 'Rui Sheng', 'Yifang Wang', 'Xinhuan Shu', 'Xiaojiao Chen', 'Tan Tang', 'Yingcai Wu'],
      venue: 'IEEE VIS Arts Program (accepted in two tracks: Pictorial and Artwork)',
      venueShort: 'VISAP2023',
      mediaCoverage: [
        {name: 'HKUST Lee Shau Kee Library', title: 'Backstory of the “Island of Loneliness”', fileLink: 'https://library.hkust.edu.hk/sc/the-island-of-loneliness-china-visap/'}, 
        {name: '艺术可视化', title: 'China VISAP\'24 | 艺术项目报道-01', fileLink: 'https://mp.weixin.qq.com/s/FL2RswucK6MWMOtHMiIu2Q'}, 
      ], 
      introduction: <div>
      <p>Loneliness and isolation are eternal emotions in human beings. Technological advancements create ample avenues, like social medias, for individuals to articulate themselves and record emotions. However, the sense of loneliness has never vanished, as their expressions are easily buried in the digital stream. We analyze tweets that express loneliness during holiday seasons but receive few responses. By superimposing digital charts on physical models, we visualize these lonely posts and generate the island of loneliness. We aim to reveal the complexities of human emotions in the digital age and reflect on the interconnections between technology, solitude, and social communication.</p>
    </div>, 
      story: 
      <Tabs defaultActiveKey="1">
          <TabPane tab="English" key="1">
            <div>
              <h4>Origin</h4>
              <p>I try to trace the origin of this project. Yet, ten minutes later, I still can’t pinpoint the first moment the idea came to be. Perhaps, as the title suggests—The Island of Loneliness—loneliness itself isn’t something that suddenly begins in an explicit instant. Maybe it’s something that has existed alongside civilization, entwined with life itself, lingering in the hearts of each of us coauthors for a very long time.</p>
              <p>Junxiu later recalled that the initial concept might go back to VISAP 2021, where the theme was (dis)connected, and ChinaVISAP, where the theme was bridges. Without bridges, there is no connection. Each person becomes an isolated island.</p>
              <p>The earliest record I found was a casual chat on February 9, 2023, about what we’d plan for this year’s art, along with a tweet reposted on February 19th--</p>
              <div className='story-image-wrapper'>
                <div className='story-image-div'><Image src={LonelyIslandStoryImgUrl1}/></div>
                <div className='story-image-div'><Image src={LonelyIslandStoryImgUrl2}/></div>
              </div>
       
              <br />
              <h4>Medium</h4>
              <p>The tweet mentioned an aunt banned from WeChat. From this, we started to ponder: what does loneliness signify and represent in contemporary society? In the age of the internet, with the explosion of information and the construction of virtual social networks, the distances between us seem to shrink. Even separated by miles, we can still text, chat, and video call through social media.</p>
              <p>Our WeChat accounts hold hundreds, even thousands, of contacts. Our social media follows interesting people and events worldwide. Sites like Xiaohongshu and Bilibili bring daily shares about life and learning… In such a context, “loneliness” may sound melodramatic. But is it truly so?</p>
              <p>Those moments when we scroll through our contacts but find no one to talk to, when we see the brilliant lives of distant others while feeling aimless, those nights when endless scrolling leaves us feeling empty… Beneath the noise of the internet, where do our souls find rest? Are the confusions about life, emotions, time, or even the universe truly eased by these virtual connections? Perhaps, sometimes. But maybe not.</p>
              <p>Online social media—a product of modern civilization—seems to have given loneliness a new meaning.</p>
              <p>As a PhD candidate previously working on a survey about social media visual analytics, I remember vividly how pioneers in visualization created outstanding works exploring issues like opinion leaders, topic evolution, and information diffusion, all of which touch on social science concepts such as communication and group dynamics.</p>
              <p>So, we thought: what if we went the other way? Beyond mainstream media and influencers, what about the countless ordinary people inhabiting social media?</p>
              <li>Perhaps they get few shares or comments.</li>
              <li>Perhaps they are whispering to themselves late at night.</li>
              <li>Perhaps they carry complex emotions beneath the likes they receive.
              </li>
              <li>Perhaps, more often than not, they are lonely.
              </li>
              <p>So, we got to work. In this phase, we chose the most direct platform—Twitter—and the most straightforward keywords: lonely, loneliness... We tried to gather the murmurs of thousands of ordinary people in the digital world—those silent words without retweets or comments, speaking of loneliness itself, and ultimately built an island constructed from these quiet echoes.</p>

              <br />
              <h4>Further Exploration?</h4>
              <p>At times, we may feel like the 52-hertz whale, singing either joyfully or sorrowfully, with our calls unheard by our kind. This “whale” could be the elderly person banned from WeChat, or perhaps each one of us.</p>
              <p>As this phase of the project draws to a close, we return to reflect on the initial question: what is loneliness, really?</p>
              <li>Is it the grass beneath our feet, growing and withering each year?</li>
              <li>Is it the waves crashing against the shore?</li>
              <li>Is it the clouds drifting and separating, then finally fading?</li>
              <br />
              <li>Is it the golden autumn leaves, or the windmill bathed in a rosy sunset?</li>
              <li>Is it the songs sung on stage, the rustle of birds returning to the forest?</li>
              <li>Is it the silhouette against the evening sun, or the reflection in a subway window?</li>
              <br />
              <li>Is it the fear of facing the unknown and making choices?</li>
              <li>Is it the clamor and confusion amid the noise?</li>
              <li>Is it that she, far away, will always remain out of reach?</li>
              <li>Or is it the creased, yellowing neck of someone on screen?</li>
              <li>Is it time moving forward, while you remain in the past?</li>
              <br />
              <li>It's like a poison, like a poppy flower, drawing us into this aching, restless, unrequited, yet secretly thrilling, gratifying, even prideful—almost “pathological” feeling.</li>
              <br />
              <p>This is an ongoing experimental art project, created by some lovely data visualization researchers and data art enthusiasts. We hope to bring future versions—2.0, 3.0, and beyond—sharing more about the meaning of loneliness.</p>

              <br />
              <p>2023, Year of the Water Rabbit – Descent of Frost</p>
              <br />
            </div>
          </TabPane>
          <TabPane tab="中文" key="2">
             <div>
              <h4>一、缘起</h4>
              <p>我正试图去追溯项目的缘起。但十分钟过去了，还是没有找到那个第一次提到这个idea的时刻。冥冥之中，好像这个作品的题目——The Island of Loneliness——一样，或许孤独本身并不是从显式的某一刻忽然开始的，而是伴随着文明出现、并随着生命共生的——或许这个idea也存在于我们每个coauthor的心中已经很久很久了。</p>
              <p>阿修后来回忆说，起初的想法可能要追溯到VISAP 2021——主题是(dis) connected和ChinaVISAP——主题是桥：没有桥，就没有连接。每个人都是一个孤岛。</p>
              <p>我翻到的最早的记录是2023年2月9日的一嘴闲聊——今年art打算做什么；以及2月19日的一则推文转发——</p>
              <div className='story-image-wrapper'>
                <div className='story-image-div'><Image src={LonelyIslandStoryImgUrl1}/></div>
                <div className='story-image-div'><Image src={LonelyIslandStoryImgUrl2}/></div>
              </div>
       
              <br />
              <h4>二、载体</h4>
              <p>从这则推文中提到的微信被封的阿姨，我们开始思考，孤独，在现当代社会的表征和意义到底是什么？互联网时代，信息的爆炸和虚拟社会网络的构建，令人与人之间的距离似乎越来越近。即便相隔万里，我们依旧可以通过社交媒体和聊天软件打字、聊天、视频。</p>
              <p>我们的微信里存储着几百甚至几千张名片；我们的社交媒体关注着全世界有意思的人和事；小红书、B站等等兴趣网站每天都有关于生活学习等方方面面的分享… 在这样的社会背景下，“孤独”这个词，似乎意味着矫情。但，事实真的如此吗？</p>
              <p>那些翻遍通讯录却依旧觉得无人可聊的时刻，那些远方的璀璨人生和眼下碌碌无为的自己，那些刷遍手机却觉得依旧空虚的夜晚… 喧闹网络的背后，我们的灵魂究竟被安放在了何处？那些对于生命、情感、时间、甚至宇宙的困惑，真的通过社交网络的连接减轻了吗？或许有，或许没有。</p>
              <p>在线社交媒体，现代文明的产物，似乎给孤独赋予了新的意义。</p>
              <p>由于自己PhD PQE做的是social media visual analytics相关的survey，我清晰地记得可视化领域的前辈们有许多特别优秀的可视化作品和系统研究的是意见领袖、话题演化、信息扩散等等的问题，其中大多涉及传播学和群体研究等社会科学知识。</p>
              <p>于是我们想：如果反其道而行之呢？除了主流媒体和微博大亨，那些在线社交媒体中的芸芸众生，又会是什么样子的呢？</p>
              <li>大概是没有多少转发量和评论量的；</li>
              <li>大概是深夜的喃喃自语；</li>
              <li>大概是隐藏在很多点赞背后的复杂情感；</li>
              <li>大概，很多时候，是孤独的。</li>
              <p>说干就干。这个阶段，我们选择了最直接的平台——Twitter和最直接的关键词——lonely/loneliness/…。我们试图搜集成千上万的普通人在网络世界中的低吟——那些零转评的、讨论孤独本身的字眼，并最终形成由这些低吟构建的，一座孤岛。</p>

              <br />
              <h4>三、更进一步？</h4>
              <p>或许总有一些时刻，我们会像那头 52 赫兹的鲸鱼一样，不管是快乐地还是悲伤地吟唱，同类永远也听不到它的声音。这头“鲸鱼”，或许是上面推文微信账号被封的老人，亦或许是我们每一个人。</p>
              <p>这个项目阶段性的完结，让我们再次思考回到起点思考，孤独，究竟是什么？</p>
              <li>是脚下一岁一枯荣的杂草吗</li>
              <li>是海边一波又一波拍打着堤岸的浪花吗</li>
              <li>是天上成群移动却渐渐分离最终消散的云吗 </li>
              <br />
              <li>是泛黄的秋叶飘落的桂花？还是晚霞晕红了的大风车？</li>
              <li>是台上人的吟唱？倦鸟归林的簌簌声？</li>
              <li>是夕阳下的剪影？还是地下铁玻璃窗前反射的倒影？</li>
              <br />
              <li>是面对未知和选择的惶恐？</li>
              <li>是喧哗中的不知所措和耳边的轰鸣？</li>
              <li>是远方的她永远留在了远方？</li>
              <li>还是视频中的人发皱泛了黄的脖颈？</li>
              <li>是时间不断在往前 而你却留在了从前？</li>
              <br />
              <li>它就像毒药 像罂粟</li>
              <li>令人沉浸在这</li>
              <li>痛苦着的 焦灼着的 不被回应着的</li>
              <li>却又不知道从何而来的</li>
              <li>窃喜着的 愉悦着的 甚至骄傲着的</li>
              <li>一种几近“病态”的心情中</li>
              <br />
              <p>这是一项持续性的实验性艺术项目，来自几个可爱的数据可视化研究者、数据艺术爱好者。我们希望可以在不久的将来有2.0, 3.0, …版本，分享给大家更多关于“孤独”的意义。</p>

              <br />
              <p>2023癸卯年·霜降</p>
              <br />
            </div>
          </TabPane>
      </Tabs>, 
      storyImageList: [LonelyIslandStoryImgUrl1, LonelyIslandStoryImgUrl2], 
      imageList: LonelyIslandStoryImgUrlList, 
      suppMaterialList: [
        {name: 'Project Page', fileLink: LonelyIslandVideoUrl},
        {name: 'PDF', fileLink: LonelyIslandPaperUrl},
        {name: 'Demo Video', fileLink: LonelyIslandVideoUrl},
        {name: 'VISAP2023 Official Website', fileLink: 'https://visap.net/2023/contributions/posts-with-no-response-the-island-of-loneliness'},
        {name: 'Info+ 2023 Official Website', fileLink: 'https://informationplusconference.com/2023/#program'},
      ], 
      detailPageData: {
        exihibitionImageInfo: {
          image: LonelyIslandStoryImgUrlList[15],
          time: '2023, 2024', 
          title: 'Posts with No Response: The Island of Loneliness',
          description: 'Loneliness and isolation are eternal emotions in human beings. Technological advancements create ample avenues, like social medias, for individuals to articulate themselves and record emotions. However, the sense of loneliness has never vanished, as their expressions are easily buried in the digital stream. We analyze tweets that express loneliness during holiday seasons but receive few responses. By superimposing digital charts on physical models, we visualize these lonely posts and generate the island of loneliness. We aim to reveal the complexities of human emotions in the digital age and reflect on the interconnections between technology, solitude, and social communication.'
        }, 
        secondaryImageInfo: {
          image: LonelyIslandStoryImgUrlList[0],
          title: 'Metaphor',
          description: <>
            <p className='description'>
              In the digital world, social media platforms can be likened to an expansive, boundless sea brimming with many semantic data. Individuals continuously contribute their emotions, feelings, and opinions to the torrential stream of information. 
            </p>
            <p className='description'>
              Each post acts as a metaphorical stone cast into the sea, with some carrying significant weight, size, or other captivating features that generate continuous ripples and water flowers, sparking mass responses within the crowd. 
            </p>
            <p className='description'>
              However, the majority of posts bear lightweight and unremarkable appearances, failing to generate any notable ripples or water flowers. These posts akin to grains of sand, simply sinking to the seabed. While they may temporarily resurface through topic resonance when certain keywords are searched, they primarily exist as unnoticed and unattended sedimentations of information.
            </p>
          </>
        }, 
        awardImageInfo: {
          image: LonelyIslandStoryImgUrlList[7],
          awardLogoList: LonelyIslandAwardLogoList
        }
      }
    },
    {
      category: [paperCategory.ALL, paperCategory.DH, paperCategory.DR],
      teaser: shijingUrl,
      title: 'Explore Mindfulness Without Deflection: A Data Art Based On The Book Of Songs', 
      titleShort: 'Explore Mindfulness without Deflection',
      year: 2022, 
      classification: 'HUMANITY APPRECIATION', 
      theme: ['Poem', 'Culture'], 
      authorList: ['Yifang Wang', 'Yifan Cao', 'Junxiu Tang', 'Yang Wang', 'Huamin Qu', 'Yingcai Wu'],
      venue: 'IEEE VIS Arts Program',
      venueShort: 'VISAP2021',
      award: [dataArtAwardCategory.VISAP2021, dataArtAwardCategory.ElectricDreams2022, dataArtAwardCategory.IIB2022],
      mediaCoverage: [
        {name: 'HKUST数据可视化实验室', title: '【VISAP2021】冬至，宜围炉，煮雪，读诗。', fileLink: 'https://mp.weixin.qq.com/s/mMoqtZrJInwg02yGTLQ3TA'}, 
        {name: '澎湃美术课', title: '求求你，文字别再密密麻麻地排了', fileLink: 'https://mp.weixin.qq.com/s/3GJ11TLT9YALqaJ9hQ5TmA'},
        {name: '一只梨', title: '给数据来点儿设计：数据可视化的N种可能性', fileLink: 'https://mp.weixin.qq.com/s/-sbNslBgCN5hHGnStHAhHQ'},
        {name: '香港科技大学广州丨信息枢纽', title: 'CMA活动 | 第一届CMA国际研讨会及展览——Electric Dreams 电子梦', fileLink: 'https://mp.weixin.qq.com/s/U_mpaS9UQoNIy0OgVoPIHw'},
        {name: 'CMA Thrust Symposium', title: 'Electric Dreams - the 1st CMA International Symposium and Exhibition', fileLink: 'https://cma.hkust-gz.edu.cn/electric-dreams/'},
      ],
      introduction: <div>
        <p>The Book of Songs is regarded as the origin of Chinese literature and has a prolonged impact on Chinese culture, aesthetics, and morality. In this work, we have analyzed the 305 poems in The Book of Songs from different dimensions. We aim to learn how various poetic imageries connect abstract themes and subjective emotions at the micro level, and how the poems connect people today and ancestors to understand the universal, everlasting, and poetical human lives at the macro level.</p>
      </div>, 
      story:
  	  <Tabs defaultActiveKey="1">
          <TabPane tab="English" key="1">
          <div>
            <p>How to interpret and carry forward traditional Chinese culture through data visualization is something I have wanted to try since I first entered this field. In the early summer of 2021, I met Yifan, with a background in Chinese literature, and Junxiu, with a background in digital media. After a few casual conversations, the three of us immediately clicked and decided to start with The Book of Songs, the first anthology of poetry in China. As the origin of Chinese poetic development, we believe that The Book of Songs possesses a unique and magical blend of content, emotions, and spirit, which sparked the subsequent flourishing of Chinese poetry, from the Chu Ci and Han Fu to the Tang and Song poetry and the Yuan operas. The cicadas hadn’t yet begun to sing, but our hearts were already restless with excitement.</p>
            <p>By coincidence, the top visualization conference we attend each year (VISAP2021) was calling for submissions for its art project. We decided to take this opportunity to see if we could create a prototype. Within a month, we used our spare time to complete the first version (V1) of our “extracurricular project”—a static infographic titled Thoughts Without Evil. Fortunately, our work was accepted by the art project. At the conference report that concluded at the end of October, our piece attracted wide attention and discussion, including from many scholars and artists involved in Art + Tech. Their interest greatly encouraged us. We decided to take it a step further—to transform our static infographic into an interactive website. Thus, the interactive version of Thoughts Without Evil (V2) came to be. We hope that this new interactive and visual format can inspire the general public to explore The Book of Songs more deeply and encourage researchers in poetry and literature to investigate from a quantitative perspective.</p>
            <p>We often joke that this “side project” truly comes from our “love-powered engine.” Will the Book of Songs project extend to v3, v4…? Will we explore and create other digital art pieces related to traditional culture? With limited time and energy, we can’t say for sure. But we still believe: what we hold close to heart will inevitably resonate in return.</p>

            <br />
            <p>2021, Year of the Metal Ox – Winter Solstice</p>
            <br />
          </div>
          </TabPane>
          <TabPane tab="中文" key="2">
          <div>
            <p>如何通过数据可视化技术对中国的传统文化进行另一个角度的诠释和传承是我进入可视化领域以来一直想尝试的事情。2021辛丑年的初夏，遇到汉语言文学背景的艺凡和数字媒体背景的俊修，三人简单的几次闲聊以后一拍即合，决定从中国的第一部诗歌总集《诗经》开始做尝试。作为诗学发展的源头，我们相信《诗经》必然拥有着一些独特和神奇的内容、情感和精神，才能引发之后从楚辞汉赋到唐诗宋词元曲等等的一整个华夏诗学的璀璨发展。蝉鸣未至，我们内心却早已躁动起来。</p>
            <p>恰巧我们可视化顶会每年都有一个艺术项目（VISAP2021）征稿，我们决定以此为契机，看看能不能做一些雏形出来。一个月中，我们利用自己的闲暇时间，完成了第一版（V1）的“课外作业”——一个静态的信息图（infograph）《思无邪》。幸运的是，我们的作品被艺术项目接收了。在今年十月底刚刚结束的会议报告中，我们的作品也得到了广泛的关注和讨论，其中还不乏很多做艺术（Art）+技术（Tech）的学者和艺术家们。这些都给了我们极大的鼓励。我们决定更进一步——将我们静态的信息图转换成一个交互式的网站。于是，就有了如今这一个交互版《思无邪》作品（V2）。我们希望这种新的交互和可视化形式，可以让普通大众对诗经产生更深的探索兴趣，也希望我们的可视化分析手段，可以启发诗学以及文学研究者们从量化等新的角度进行研究。</p>
            <p>我们经常自嘲，这个“课外项目”真是属于“为爱发电”。未来诗经项目是否还会延伸出v3、v4...版本？是否还会探索并创作其他与传统文化相关的数字艺术作品？由于时间和精力有限，我们都难以确定，但我们依旧相信：念念不忘，必有回响。</p>

            <br />
            <p>2021辛丑年·冬至</p>
            <br />
          </div>
          </TabPane>
      </Tabs>, 

      imageList: Visap2021ImgUrlList, 
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://wangyifang.github.io/the-book-of-songs/'},
        {name: 'PDF', fileLink: visap2021PaperUrl},
        {name: 'Demo Video', fileLink: visap2021VideoUrl},
        {name: 'Supp Material', fileLink: visap2021SuppUrl},
        {name: 'Slides', fileLink: visap2021SlidesUrl},
        {name: 'Talk', fileLink: 'https://www.youtube.com/watch?v=5fNTfPpeXQs'},
      ], 
      detailPageData: {
        exihibitionImageInfo: {
          image: Visap2021ImgUrlList[2],
          time: '2021, 2022', 
          title: 'Explore Mindfulness Without Deflection: A Data Art Based On The Book Of Songs',
          description: <p>
            The Book of Songs is regarded as the origin of Chinese literature and has a prolonged impact on Chinese culture, aesthetics, and morality. In this work, we have analyzed the 305 poems in The Book of Songs from different dimensions. We aim to learn how various poetic imageries connect abstract themes and subjective emotions at the micro level, and how the poems connect people today and ancestors to understand the universal, everlasting, and poetical human lives at the macro level.
          </p>
        }, 
        secondaryImageInfo: {
          image: Visap2021ImgUrlList[1],
          title: 'The Book of Songs',
          description: <p className='description'>
            The Book of Songs is widely acknowledged as the origin of traditional Chinese culture and highly impacted the whole East Asian culture. It is officially assembled by the famous founder of Confucianist, Confucius, who lived in the Late Zhou Dynasty around 3000 years ago. This epoch is considered paralleled with the Axial Period of world history, and Confucianist is regarded as one of the fundamental East Asian philosophies. The Book of Songs represents secular and religious values Confucius approved in accord with his own philosophical system, which brings a profound impact on traditional Chinese culture, aesthetics, and morality. Recorded from 1100 BC to 500 BC, The Book of Songs consists of 305 poems with three genres, namely, Songs, Odes and Epics, and Hymns [14]. Across different genres, the poems cover various themes to portray the whole society in the Late Zhou Dynasty, including not only ballads, folk-customs of ordinary people, but also sacrifice, banquet, and the musical dance of nobility.
          </p>
        }, 
        awardImageInfo: {
          image: Visap2021ImgUrlList[0],
          awardLogoList: Visap2021AwardLogoList
        }
      }
    },
    {
      category: [paperCategory.ALL, paperCategory.DH, paperCategory.DR],
      teaser: ChinaInMapsImgUrl,
      year: '2022-2024', 
      titleShort: 'China in Maps',
      title: 'China in Maps: 500 Years of Evolving Images',
      classification: 'HUMANITY APPRECIATION', 
      theme: ['Culture'], 
      authorList: ['Kentaro Takahira', 'Boyu Li', 'Wai Tong', 'Linping Yuan', 'Yifang Wang', 'Leni Yang', 'Huamin Qu', 'etc.'],
      venue: '',
      venueShort: '',
      award: [],
      mediaCoverage: [], 
      introduction: <div>
        <p>Step into a world prior to the age of GPS and wayfinding within our fingertips.</p>
        <p>How did people create and utilize maps centuries ago? When we examine old maps, besides appreciating their beauty, we may ponder why they were not primarily created for navigation and did not accurately describe geographic reality. Here is an era of ancient mapmaking when representation of reality was not of foremost concern.</p>
        <p>The HKUST Library has amassed a significant collection of Western printed maps of China spanning the 16th to 19th centuries. Since 2015, thanks to the generous donation by Dr. Ko Pui-Shuen, this collection was further enriched. The exhibition features a curated selection of items from this valuable collection, highlighting how the land and geography of China were portrayed by European and Chinese mapmakers.</p>
        <p>These maps guide us in transitioning from viewing the world through our contemporary lens to embracing the perspectives of mapmakers from different times in the past. Their presentations may break perception stereotypes, challenge our preconceived notions about the advancement of knowledge and technology throughout history, and impart fresh understanding of ancient maps and mapmaking.</p>
        <p>To enrich the stories we convey, the exhibition includes important maps and artifacts beyond those of the HKUST collection. While most of these are image reproductions, there are also notable exhibits such as a special original map on loan from local collector Mr. Ronald Tham and Nestorian crosses from the University Museum and Art Gallery of the University of Hong Kong. We express our deepest appreciation for their support of this exhibition.</p>
      </div>, 
      story: 
      <Tabs defaultActiveKey="1">
      </Tabs>, 
      imageList: ChinaInMapsImgUrlList, 
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://library.hkust.edu.hk/china-in-maps/'},
        // {name: 'PDF', fileLink: './xxxxxx'},
        {name: 'Demo Video', fileLink: ChinaInMapsVideoUrl},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ], 
      detailPageData: {
        exihibitionImageInfo: {
          time: '2022-2024', 
          image: ChinaInMapsImgUrlList[0],
          title: 'China in Maps: 500 Years of Evolving Images',
          description: <>
            <p className='description'>Step into a world prior to the age of GPS and wayfinding within our fingertips.</p>
            <p className='description'>How did people create and utilize maps centuries ago? When we examine old maps, besides appreciating their beauty, we may ponder why they were not primarily created for navigation and did not accurately describe geographic reality. Here is an era of ancient mapmaking when representation of reality was not of foremost concern.</p>
            <p className='description'>The HKUST Library has amassed a significant collection of Western printed maps of China spanning the 16th to 19th centuries. Since 2015, thanks to the generous donation by Dr. Ko Pui-Shuen, this collection was further enriched. The exhibition features a curated selection of items from this valuable collection, highlighting how the land and geography of China were portrayed by European and Chinese mapmakers.</p>
            <p className='description'>These maps guide us in transitioning from viewing the world through our contemporary lens to embracing the perspectives of mapmakers from different times in the past. Their presentations may break perception stereotypes, challenge our preconceived notions about the advancement of knowledge and technology throughout history, and impart fresh understanding of ancient maps and mapmaking.</p>
            <p className='description'>To enrich the stories we convey, the exhibition includes important maps and artifacts beyond those of the HKUST collection. While most of these are image reproductions, there are also notable exhibits such as a special original map on loan from local collector Mr. Ronald Tham and Nestorian crosses from the University Museum and Art Gallery of the University of Hong Kong. We express our deepest appreciation for their support of this exhibition.</p>                  
          </>
        }, 
        secondaryImageInfo: {
          image: ChinaInMapsImgUrlList[1],
          title: '',
          description: 'Kunyu Wanguo Quantu [Complete Geographical Map of all the Kingdoms of the World]'
        }, 
        awardImageInfo: {
          image: ChinaInMapsImgUrlList[0],
          awardLogoList: []
        }
      }
    },
    {
      category: [paperCategory.ALL, paperCategory.DH, paperCategory.DR],
      teaser: dunhuangUrl,
      year: 2022, 
      titleShort: 'Imagination to the Universe',
      title: 'Imagination to the Universe: Mingling the Ancient and the Present',
      classification: 'HUMANITY APPRECIATION', 
      theme: ['Culture'], 
      authorList: ['Junxiu Tang', 'Yifang Wang', 'Jiayi Zhou', 'Xinhuan Shu', 'Tan Tang', 'Huamin Qu', 'Yingcai Wu'],
      venue: '',
      venueShort: '',
      award: [],
      mediaCoverage: [], 
      story: 
      <Tabs defaultActiveKey="1">
        <TabPane tab="English" key="1">
          <div>
            <p>
            For thousands of years, the descendants of China have never ceased to imagine and explore the boundless sky and vast universe. From the self-questioning of "Where does the sky’s edge lie?" to the romantic myth of "Wu Zhi, sleepless, leans on the cassia tree as dew-wet hare feet fly through the night," from the time-perception embodied in "The Big Dipper tilts as the Southern Dipper sinks," to the natural insight of "The sky is like a lid, the earth like an overturned bowl, each high at the center and low at the edges," and to the present, with Shenzhou, Tiangong, Yutu, and Zhurong, we have recorded myths, penned poetry, and imbued the cosmos with rich imagination and delicate emotions. Alongside, we have pursued knowledge and sought truth, attempting to break free from the shackles to explore the vast universe. The Flight to the Sky, Embrace of the Moon, Gathering of Stars series adopts the artistic style of Dunhuang murals, blending ancient Chinese legends and texts with contemporary aerospace exploration missions. The theme "Visible Classics" unfolds across three layers of self-awareness, external exploration, and the arrival at the unknown, interpreting the Chinese cultural heritage and technological exploration across space and time, and thus unfolds in three chapters: Flight to the Sky: Breaking the Chaos, Embrace of the Moon: Conversations with Chang'e, and Gathering of Stars: Uniting the Heavens.
            </p>

            <br />
            <h4>Flight to the Sky: Breaking the Chaos</h4>
            <p>
            "Flight to the Sky" reflects humanity’s evolving understanding of the heavens. The Book of Jin records, "The sky is round like an umbrella, and the earth is square like a chessboard." This "round sky, square earth" cosmology is a significant ancient understanding of the universe. This piece begins with the caisson patterns of the Dunhuang caves, using motifs like petal scrolls and circular florals to depict the ancient worldview. Gazing up from below, it appears like a colossal lid covering the sky. Around and within this lid are dark, unexplored spaces, symbolizing the uncharted and boundless cosmos.
            </p>
            <p>
            Through AR interaction, the viewer is transported centuries forward to present-day China. The artwork depicts the rockets launched as part of China's manned space program up to May 2022, with the center showcasing the Chinese space station. Twenty Shenzhou spacecraft are arranged counterclockwise according to their launch dates, piercing through the "round sky, square earth" caisson, breaking through the ancient conception of the cosmos. From unmanned missions to cargo and crewed missions, to orbit docking, spacewalking, and space station occupancy, the unrelenting efforts of several decades have seen the Chinese people embark on the journey of space exploration, reaching into the deep space and venturing into the unknown.
            </p>

            <br />
            <h4>Embrace of the Moon: Conversations with Chang'e</h4>
            <p>
            The moon, our nearest celestial neighbor, has long been a subject of sentimental musings and the first stop on the path of space exploration. Sitting under a tree with a jug of wine and a zither, one might find solace, even in solitude, with only the moonlight for company.</p>
            <li>Under the bright moon, do you see those faint shadows? Could it be Wu Gang chopping down the cassia tree? Or perhaps the jade rabbit and toad frolicking nearby?</li>
            <li>As the moonlight glows, do you see the clouds around the halo? Could it be Chang'e herself, dancing gracefully outside the palace?</li>
            <li>Across a thousand miles, do you see those wisps of moonlight like drifting smoke? Could they be the dust kicked up by Wangsher's chariot, rushing across the night sky?</li>
            <br />

            <p>In the silence of the night, as the wind stirs and cicadas sing, we look up at the bright moon, a motif found countless times in Chinese literature. The romantic essence of the Chinese soul endows the moon with numerous poetic names.</p>
            {/* <p>“此时瞻白兔，直欲数秋毫。”这是杜甫避乱蜀中望月思乡的感叹；“古祠近月蟾桂寒，椒花坠红湿云间。”这是李贺巫山楚魂寻梦到巫山的哀婉； “天汉看珠蚌，星桥视桂花。”这是庚信撒月上桂 花散于夜空幻化做星斗的妙想…</p> */}
            <p>We compiled various ancient names for the moon found in classical literature and displayed them in AR under the moonlit sky. The AR visualization represents these names as a word cloud, where the size of each word indicates its frequency. Terms like "toad," "rabbit," and "cassia" dominate, referencing myths. These names appear as silhouettes on willow branches, reminiscent of the billowing ribbons of celestial beings depicted in Dunhuang murals, embodying both the timeless poetry and myth associated with the moon. Circling the moon is a sequence of star trails, representing the launch dates and durations of China’s lunar missions (Chang'e and Yutu series), guarding the moon and Earth alongside ancient stars. Now, when people gaze at the moon, they not only see myths but also the envoys from Earth, reaching out across the sky.</p>
            {/* <p>「揽月：话婵娟」以古代典籍中月的别称以及现代的月球探测任务为数据，古今中与月有关的故事在月色下交融。</p>
            <p>月晕周围则出现了一道道星轨，记录着奔月系列航天器（嫦娥系列和玉兔系列）的发射时间和停留时长，与远古的众星一起，守护着月亮与地球。</p>
            <p>从此，人们遥望月亮的时候，不仅仅只有神话，还有可以与来自地球的使者对话。</p> */}

            <br />
            <h4>Gathering of Stars: Uniting the Heavens</h4>
            <p>The exploration of the vast starry sky has been an ongoing endeavor. China's planetary exploration mission is named Tianwen, with the first Mars mission called Tianwen-1. In July 2020, Tianwen-1, carrying the Zhurong rover, embarked on its journey to Mars. The name Tianwen, drawn from Qu Yuan's long poem, symbolizes the Chinese people's resilient pursuit of truth and their cultural heritage of exploring nature and the cosmos, capturing the boundless quest for scientific and technological innovation.</p>
            <p>By combining the ancient text Tianwen and modern Mars exploration events, Gathering of Stars uses the border patterns of Dunhuang murals as the primary imagery, portraying China’s exploration of the most distant planet yet. The bottom half incorporates square patterns from mural borders and original text from Tianwen, color-coded to reflect themes: questions about heaven (blue), earth (green), deities (red), and humanity (orange).</p>
            <p>In AR, the original text appears on these border patterns. Additionally, we categorized the questions on heaven, earth, and gods, and represented them with corresponding color-coded area charts on lotus petals above. The petal size represents the frequency of related lines in the poem.</p>
            <p>The outermost layer of the AR features flying ribbons, chronologically depicting the major milestones of Tianwen-1 and Zhurong. This reflection of two years of groundbreaking advancements marks the steps China has taken toward exploring the mysteries of distant planets.</p>

            <h4>Conclusion</h4>
            <p>From breaking through the primeval chaos to reveal the true heavens and earth, to reaching for the moon and witnessing Chang'e’s beauty, and finally to exploring Mars and uncovering planetary secrets… we continue our quest for truth and strive to coexist harmoniously with the natural cosmos. We transcend boundaries, journeying ever farther into interstellar space, leaving traces of China’s ancient stories and texts across the vast universe.</p>

            <br />
            <p>2024, Year of the Wood Dragon – Beginning of Autumn</p>
            <br />
          </div>
        </TabPane>
        <TabPane tab="中文" key="2">
        <div>
          <p>
            千百年来，华夏子孙从未停止过对无际天空和浩渺宇宙的想象和求索。从“九天之际，安放安属”的自我困惑、“吴质不眠倚桂树，露脚斜飞湿寒兔”的浪漫神话，到“北斗阑干南斗斜”的时间感知、“天似盖笠，地法覆槃，天地各中高外下”的自然洞察，再到现如今的神舟天宫、玉兔祝融探测器，我们一边记录神话谱写诗句，将丰富的想象和委婉的情愫寄托于天地星辰，一边纠正认知、追寻真理，试图冲破桎梏去茫茫宇宙一探究竟。飞天·揽月·聚星系列作品借以敦煌壁画的艺术形式，混合中国古代典故典籍和当代航空航天标志性探测任务，以“可视观典”的主题，从自我认知、对外探索、和抵达未知三个层次依次解读中华民族对天地宇宙跨时空的文化传承和科技探索，并由此产生了“飞天：破鸿蒙”、“揽月：话婵娟”、“聚星：混希夷”三个篇章。
          </p>

          <br />
          <h4>飞天：破鸿蒙</h4>
          <p>
            “飞天”诠释了人们对天空理解的变化。《晋书·天文志》中这样记载：“天员（圆）如张盖，地方如棋局。”「天圆地方」的盖天说是古人理解天地的一种重要的宇宙学说。本作品首先以敦煌石窟中的藻井为意象，采用了捲瓣莲花、圈花等纹样，刻画出古人天圆地方的宇宙世界观。由下仰望，如同一张巨大的盖子覆盖于天空顶端。其周围和中心墨黑的地方，尚未被人们探索，犹如混沌宇宙，充满未知，不见边际。
          </p>
          <p>
            通过AR交互，作品将观众带到了千百年后的中国。画面中展现的是截止2022年5月，中国载人航天工程所发射的火箭，藻井中间为中国宇宙空间站。二十艘神舟系列飞船按照发射时间逆时针排序，直插云霄，冲破了头上“天圆地方”的虚无藻井，也冲破了古人的宇宙观。从无人、载物、载人，到在轨停留、太空行走、进驻空间站，在数十余年的不懈努力下，中国人终于迈出了探索宇宙的脚步，触及深空，探索未知。
          </p>

          <br />
          <h4>揽月：话婵娟</h4>
          <p>
            月亮是距离我们最近的天体，是自古以来人们借景抒怀的对象，也是人们前往宇宙探索的第一站。一壶酒一把琴坐树下，即便独酌，依旧可以对影成三人，好不自在。
          </p>
          <li>皓月当空，你看那些许昏暗的地方，是不是吴刚在伐桂树？亦或是蟾蜍和玉兔在周围嬉闹？</li>
          <li>月光皎洁，你看那些月晕旁的祥云，是不是化作了嫦娥模样，在广寒宫外翩翩起舞？</li>
          <li>月明千里，你看那些似轻烟的月光，是不是望舒驾驭月车匆匆驶过，扬起的仙尘？</li>
          <br />

          <p>深夜，风起蝉鸣。</p>
          <p>举头望明月，中国古典文学中与月相关的诗歌、文章不尽其数。文人墨客借由中国人骨子里的浪漫，赋予了头顶的明月不少别称。</p>
          <p>“此时瞻白兔，直欲数秋毫。”这是杜甫避乱蜀中望月思乡的感叹；“古祠近月蟾桂寒，椒花坠红湿云间。”这是李贺巫山楚魂寻梦到巫山的哀婉； “天汉看珠蚌，星桥视桂花。”这是庚信撒月上桂 花散于夜空幻化做星斗的妙想…</p>
          <p>我们整理了古代文学作品中古人对月的别称，在AR下将他们展示在月色里。在AR中，圆月上呈现出是别称字云，文字大小编码该文字在所有月的别称中出现的频率，“蟾”、“兔”、“桂”等传说典故占了多数。在AR下，字云可视化详细展示了与中华神话典故有关的别称，它们出现在剪影里、柳梢上，带有月亮意象的诗词宛如柳叶与飘带在风中飞舞。柳枝的形态参考了敦煌壁画人字坡飞天中上扬巾带的形态。</p>
          <p>「揽月：话婵娟」以古代典籍中月的别称以及现代的月球探测任务为数据，古今中与月有关的故事在月色下交融。</p>
          <p>月晕周围则出现了一道道星轨，记录着奔月系列航天器（嫦娥系列和玉兔系列）的发射时间和停留时长，与远古的众星一起，守护着月亮与地球。</p>
          <p>从此，人们遥望月亮的时候，不仅仅只有神话，还有可以与来自地球的使者对话。</p>

          <br />
          <h4>聚星：混希夷</h4>
          <p>华夏子孙对浩瀚星空的探索从未停止。中国行星探测任务被命名为“天问系列”，首次火星探测任务被命名为“天问一号”。2020年7月，天问一号搭载着祝融号，向火星出发。“天问”这一名称来源于屈原长诗《天问》，“表达了中华民族对真理追求的坚韧与执着，体现了对自然和宇宙空间探索的文化传承，寓意探求科学真理征途漫漫，追求科技创新永无止境。”</p>
          <p>结合古代典籍《天问》和现代火星探测事件序列，“聚星”以敦煌壁画的边纹修饰图案为主要意象，刻画中国迄今为止最遥远的星球探索。</p>
          <p>下半部分的方块状纹样结合壁画边纹和典籍原文内容，用颜色分别编码了《天问》中与探索求知有关的原文：问天（蓝色）、问地（绿色）、问神（红色）、问人（橙色）。</p>
          <p>在AR之下，原文呈现在边纹图案上。此外，我们还对问天、问地、问神的原文详细标注了所问的类别，并将其在上方的莲花花瓣上用相应的色系面积图展现。花瓣大小编码了对应诗句的数量。</p>
          <p>AR中画面最外部的飞天飘带逆时针形成了天问一号和祝融号探测任务的主要时间节点。回顾了两年间取得的一次又一次突破性进展。</p>

          <h4>结语</h4>
          <p>从冲破鸿蒙看到真实的天地，到上天揽月一堵“婵娟”真容，再到登录火星拨开行星的奥妙…我们不断探索，寻求更多的真理，以期能与自然宇宙和谐共存；我们不断超越，走向更远的星际，在这浩渺宇宙留下专属于华夏典故和典籍的痕迹。</p>

          <br />
          <p>2024甲辰年·立秋</p>
          <br />
        </div>
        </TabPane>
      </Tabs>, 
      

      imageList: DunhuangImgUrlList, 
      suppMaterialList: [
        {name: 'Project Page', fileLink: 'https://fs-tm-gs.github.io/'},
        // {name: 'PDF', fileLink: './xxxxxx'},
        // {name: 'Demo Video', fileLink: './xxxxxx'},
        // {name: 'Slides', fileLink: './xxxxxx'},
        // {name: 'Talk', fileLink: './xxxxxx'},
      ], 
      detailPageData: {
        exihibitionImageInfo: {
          time: '2022', 
          image: DunhuangImgUrlList[1],
          title: 'Imagination to the Universe: Mingling the Ancient and the Present',
          description: <p className='description'>
                        For thousands of years, the descendants of China have never ceased to imagine and explore the boundless sky and vast universe. We have recorded myths, penned poetry, and imbued the cosmos with rich imagination and delicate emotions. Alongside, we have pursued knowledge and sought truth, attempting to break free from the shackles to explore the vast universe. The Flight to the Sky, Embrace of the Moon, Gathering of Stars series adopts the artistic style of Dunhuang murals, blending ancient Chinese legends and texts with contemporary aerospace exploration missions. The theme "Visible Classics" unfolds across three layers of self-awareness, external exploration, and the arrival at the unknown, interpreting the Chinese cultural heritage and technological exploration across space and time, and thus unfolds in three chapters: Flight to the Sky: Breaking the Chaos, Embrace of the Moon: Conversations with Chang'e, and Gathering of Stars: Uniting the Heavens.
                        </p>
        }, 
        secondaryImageInfo: {
          image: DunhuangImgUrlList[3],
          title: '',
          description: ''
        }, 
        awardImageInfo: {
          image: DunhuangImgUrlList[5],
          awardLogoList: []
        }
      }
    },
    // {
    //   category: [paperCategory.ALL, paperCategory.DR],
    //   teaser: vis22ReflectionUrl,
    //   title: '[Project for Fun] My VIS2022 Reflection',
    //   authorList: ['Yifang Wang'],
    //   venue: '',
    //   venueShort: '',
    //   award: [],
    //   suppMaterialList: [
    //     {name: 'Project Page', fileLink: 'http://vis2022reflect.hkustvis.org/'},
    //     // {name: 'PDF', fileLink: './xxxxxx'},
    //     // {name: 'Demo Video', fileLink: './xxxxxx'},
    //     // {name: 'Slides', fileLink: './xxxxxx'},
    //     // {name: 'Talk', fileLink: './xxxxxx'},
    //   ]
    // },
  ],
  experiencePage: [
    {category: 'Experience', institute: 'Kellogg School of Management, Northwestern University', institutionLogo: nuUrl, location: 'Evanston, USA', timePeriod: 'Sept. 2022 - Now', role: 'Post-Doctoral Fellow', supervisor: 'Prof. Dashun Wang'},
    {category: 'Experience', institute: 'VisLab, HKUST', institutionLogo: hkustUrl, location: 'Hong Kong, China', timePeriod: 'Sept. 2018 - Aug. 2022', role: 'PhD', supervisor: 'Prof. Huamin Qu'},
    {category: 'Experience', institute: 'Zhejiang Lab', institutionLogo: zhejianglabUrl, location: 'Hangzhou, China', timePeriod: 'Sept. 2020 – Apr.2021', role: 'Visiting Scholar', supervisor: 'Prof. Yingcai Wu'},
    {category: 'Experience', institute: 'State Key Lab of CAD & CG, Zhejiang University', institutionLogo: zjuUrl, location: 'Hangzhou, China', timePeriod: 'Nov. 2019 – Apr.2020', role: 'Visiting Scholar', supervisor: 'Prof. Yingcai Wu'},
    {category: 'Experience', institute: 'State Key Lab of CAD & CG, Zhejiang University', institutionLogo: zjuUrl, location: 'Hangzhou, China', timePeriod: 'Sept. 2016 - Jun. 2018', role: 'Research Assistant (UG)', supervisor: 'Prof. Yingcai Wu'},

    {category: 'Service', role: 'Program Committee', venueList: ['IEEE PacificVis Conference (TVCG Journal Track, 2025)', 'IEEE VIS Conference (VAST, InfoVis, and SciVis) Short Paper (VIS Short Paper, 2023-2024)']},
    {category: 'Service', role: 'Organization Committee', venueList: ['IEEE VIS Panel (2024): What Do Visualization Art Projects Bring to the VIS Community?']},
    {category: 'Service', role: 'Conference Review', venueList: [
      'IEEE VIS Conference (VAST, InfoVis, and SciVis) (VIS, 2019-2024)',
      'ACM Conference on Human Factors in Computing Systems (CHI, 2023-2024)',
      'ACM CHI Late-Breaking Work (2023-2024)',
      'IEEE Eurographics / VGTC Symposium on Visualization (EuroVis, 2023)',
      'IEEE Pacific Visualization Symposium (PacificVis, 2021-2023)',
      'China Visualization and Visual Analytics Conference (ChinaVis, 2020-2024)',
      'International Symposium of Chinese CHI (ChineseCHI, 2022)',
      'International Conference on Computational Social Science (IC2S2, social science conference) (2024)'
    ]},
    {category: 'Service', role: 'Journal Review', venueList: ['IEEE Transactions on Visualization and Computer Graphics (TVCG, 2021-2024)', 'IEEE Computer Graphics and Applications (CG\&A, 2021-2023)', 'Visual Informatics (VI, 2023-2024)', 'Computer Science Review (2024)']}, 

    {category: 'Teaching', role: 'Teaching Assistant Coordinator (TAC, 2020-2022)', title: 'CSE Department, The Hong Kong University of Science and Technology'}, 
    {category: 'Teaching', role: 'Teaching Assistant', title: 'Course: COMP2711: Discrete Mathematical Tools for Computer Science, The Hong Kong University of Science and Technology'}, 
    {category: 'Teaching', role: 'Teaching Assistant', title: 'Course: COMP4431: Multimedia Computing, The Hong Kong University of Science and Technology'}, 

    {category: 'R&D', teaser: delphiUrl, title: 'Delphi: AI-powered Visual Analytics Solutions for Communication and Presentation Coaching', introduction: 'The project aims to develop AI-based visual analytics techniques and visualization systems to assist both coaches and public speaking trainees to conduct better presentation training. The audio, text, gesture and posture of the presentations is quantitatively analyzed to guide efficient presentation training.'},
    {category: 'R&D', teaser: urbanUrl, title: 'People-Aware Smart City: A People-Centered Integration, Mining and Decision Framework', introduction: 'The project proposed a people-aware smart city framework, which integrates data extracted continuously from people (air quality data and financial market data), discovers people’s needs from integrated multi-source data, and finally determines the best resource allocation plans to satisfy people’s needs.'},
    {category: 'R&D', teaser: pulse2Url, title: 'Pulse: Data-Driven Smart Campus', introduction: 'The project aims to promote a smart campus which supports effective and smart decision-makings through the combined power of IoT devices, big data, and AI.'},
  ],
  contactPageSM: [
    {platform: 'CV', link: cvUrl, content: 'CV', platformLogo: cvIconUrlsm},
    {platform: 'Email', link: 'mailto:yifang.wang@kellogg.northwestern.edu', content: 'yifang.wang@kellogg.northwestern.edu', platformLogo: emailIconUrlsm},
    {platform: 'Google Scholar', link: 'https://scholar.google.com/citations?user=3qWVVUwAAAAJ&hl=zh-CN', content: 'Yifang Wang', platformLogo: scholarIconUrlsm},
    {platform: 'Twitter', link: 'https://twitter.com/yifanginhz', content: '@yifanginhz', platformLogo: twitterIconUrlsm},
  ],
  contactPage: [
    {platform: 'Email', link: 'mailto:yifang.wang@kellogg.northwestern.edu', content: 'yifang.wang@kellogg.northwestern.edu', platformLogo: emailIconUrl},
    {platform: 'Google Scholar', link: 'https://scholar.google.com/citations?user=3qWVVUwAAAAJ&hl=zh-CN', content: 'Yifang Wang', platformLogo: scholarIconUrl},
    {platform: 'Github', link: 'https://github.com/WangYifang', content: '@WangYifang', platformLogo: githubIconUrl},
    {platform: 'Twitter', link: 'https://twitter.com/yifanginhz', content: '@yifanginhz', platformLogo: twitterIconUrl},
    {platform: '500px', link: 'https://500px.com.cn/yifangwang', content: 'https://500px.com.cn/yifangwang', platformLogo: photoIconUrl},
  ],
  lifePage: {
    hangzhou: 'I love Hangzhou, my hometown.',
    usa: 'Explore the world.',
    academicFamily: 'So lucky to be with two adacmic families. Even though I have leaved my Ph.D. group, I feel always be a part of them.'
  }
}
